import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
  useRef,
} from "react";
import { GetAccounts, GetAccountsDetails } from "../../API";
import { toast } from "react-toastify";
import Table from "../../component/newTable";
import { Context } from "../../context";
import { useTranslation } from "react-i18next";
import HistoryPopUp from "../../component/transactionsHistoryPopUp";
import UpdatePopUp from "../../component/updateUserPopUp";
import CreateNewAccountPopUp from "../../component/createNewAccountPopUp";
import Header from "../../component/header";
import "./style.css";

export default function Accounts() {
  const { setHistoryPopUp, setUpdateUserPopUp, setCreateUserPopUp, reload } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState();
  const [selectedEmail, setSelectedEmail] = useState();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const requestsDelay = useRef(null);
  const lastSearchUserName = useRef(null);
  const lastSearchEmail = useRef(null);
  const [pageCount, setPageCount] = useState();
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [clientID, setClientID] = useState(null);
  const [toggleSideBar] = useState(false);
  const showUpdatePopUp = (id) => {
    setClientID(id);
    setTimeout(() => {
      setUpdateUserPopUp(true);
    }, 200);
  };

  const showClientHistory = (id) => {
    setClientID(id);
    setHistoryPopUp(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <div className="tableHeaderFilter">
            <div>{t("userName")}</div>
            <input
              type="text"
              className="searchFilter"
              value={selectedUserName}
              onChange={(e) => setSelectedUserName(e.target.value)}
              placeholder="Search"
            />
          </div>
        ),
        accessor: "Username",
      },
      {
        Header: (
          <div className="tableHeaderFilter">
            <div>{t("email")}</div>
            <input
              type="text"
              className="searchFilter"
              value={selectedEmail}
              onChange={(e) => setSelectedEmail(e.target.value)}
              placeholder="Search"
            />
          </div>
        ),
        accessor: "Email",
        Cell: ({ row: { original } }) => {
          if (original.Email !== null) return <div>{original.Email}</div>;
          else return <div>-</div>;
        },
      }, {
        Header: t("CompanyName"),
        accessor: "CompanyName",
        Cell: ({ row: { original } }) => {
          if (original.CompanyName !== null) return <div>{original.CompanyName}</div>;
          else return <div>-</div>;
        },
      },
      {
        Header: t("Balance"),
        accessor: "balance",
        Cell: ({ row: { original } }) => {
          if (original.balance !== null)
            return (
              <div>
                {original.balance.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
              </div>
            );
          else return 0;
        },
      },
      {
        Header: `${t("credit")}(${t("currency")})`,
        accessor: "Credit",
      },
      {
        Header: "",
        accessor: "ID",
        Cell: ({ row: { original } }) => {
          return (
            <div className="transactionReportActions">
              <i
                className="fas fa-pen editIconStyle"
                onClick={() => showUpdatePopUp(original.ID)}
              ></i>
              <i
                className="fas fa-eye eyeIcon"
                onClick={() => showClientHistory(original.ID)}
              ></i>
            </div>
          );
        },
      },
    ],
    [t, reload]
  );
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      setData([]);

      lastSearchUserName.current = selectedUserName;
      lastSearchEmail.current = selectedEmail;
      if (requestsDelay.current) return;
      requestsDelay.current = setTimeout(() => {
        requestsDelay.current = null;
        GetAccounts({
          "@page": pageIndex,
          "@rowsPerPage": pageSize,
          "@username": selectedUserName,
          "@email": selectedEmail,
        })
          .then((response) => {
            setIsLoading(false);
            setData(response.data.table1);
            setPageCount(response.data.table2[0].Total);
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }, 2000);
    },
    [selectedUserName, selectedEmail, reload]
  );

  const exportHandler = () => {
    setIsLoadingBtn(true);
    GetAccountsDetails({
      "@username": selectedUserName,
      "@email": selectedEmail,
    })
      .then((response) => {
        setIsLoadingBtn(false);
        let link = document.createElement("a");

        link.download = "Accounts";
        link.href =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          response.data;

        if (/android|iPad|iPhone|iPod/i.test(navigator.userAgent))
          link.download += ".xlsx";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setIsLoadingBtn(false);
      });
  };

  return (
    <div className="accountContainer">
      <Header
        title={t("accountsTitle")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div className="createNewAccountContainer">
        <button
          className="exportAccountsBtn"
          onClick={() => exportHandler()}
          disabled={isLoadingBtn}
        >
          {t("exportReport")}
          {isLoadingBtn && (
            <span className="spinner-border" role="status"></span>
          )}
        </button>
        <button
          className="createNewAccountBtn"
          onClick={() => setCreateUserPopUp(true)}>
          {t("newAccount")}
        </button>
      </div>

      <div className="tableDiv">
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={isLoading}
          TotalRows={pageCount}
          tableHeader={t("accountsTitle")}
        />
      </div>

      <HistoryPopUp clientID={clientID} />
      <UpdatePopUp clientID={clientID} />
      <CreateNewAccountPopUp />
    </div>
  );
}
