import React, { useState, useContext } from "react";
import PopUp from "../../component/popUp";
import { toast } from "react-toastify";
import { DeletePriceList } from "../../API";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import "./style.css";


export default function DeletePriceListPopUp({ id, name }) {
  const { deletePriceListPopUp, setDeletePriceListPopUp, reload, setReload } = useContext(Context);
  const [deletePriceListLoading, setDeletePriceListLoading] = useState(false);
  const handleClose = () => { setDeletePriceListPopUp(false); }

  const { t, i18n } = useTranslation();

  const remove = () => {
    setDeletePriceListLoading(true);
    DeletePriceList({
      "@ID": id
    })
      .then((response) => {
        setDeletePriceListLoading(false);
        toast.success(t("PriceListDeletedSuccessfully"));
        setReload(!reload);
        setDeletePriceListPopUp(false);
      })
      .catch((err) => {
        setDeletePriceListLoading(false);
        toast.error(`${err}`);
      });
  }
  const popUpTitle = `${t("deletePriceList")}`;

  const popUpFooter = (
    <>
      <button
        className="footerPopUpBtns"
        disabled={deletePriceListLoading}
        onClick={() => remove()}
      >
        {deletePriceListLoading && (
          <span className="spinner-border text-dark" role="status"></span>
        )}
        {t("Delete")}

      </button>
      <button className="closeBtn" onClick={handleClose}>
        {t("closeBtn")}
      </button>
    </>
  );


  return (

    <PopUp
      popUpTitle={popUpTitle}
      popUpFooter={popUpFooter}
      showPopUp={deletePriceListPopUp}
      closePopUp={handleClose}
    >
      <div className={`deleteConfirmationContainer ${i18n.language}`}>{t("BusinessSectorDeleteConfirmMsg")}{name} ?</div>
    </PopUp>
  );
}
