import React, { useState, useMemo, useCallback, useContext, useEffect } from "react";
import { GetUserCreditHistory, GetAccountSenders, GetAccountInfo } from "../../API";
import { toast } from "react-toastify";
import Table from "../../component/newTable";
import PopUp from "../../component/popUp";
import { Context } from "../../context";
import { useTranslation } from "react-i18next";
import CurrencyFormat from 'react-currency-format';
export default function HistoryPopUp({
  clientID

}) {
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [historyPageCount, setHistoryPageCount] = useState();
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [profilePhoneNumber, setProfilePhoneNumber] = useState();
  const [accountSMSBalance, setAccountSMSBalance] = useState();
  const [sendersCount, setSendersCount] = useState();
  const { historyPopUp, setHistoryPopUp } = useContext(Context);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [transactionsOpen, settransactionsOpen] = useState(true);
  const [AccountInfoOpen, setAccountInfoOpen] = useState(false);
  // const [isLoadingOperations, setIsLoadingOperations] = useState(false);
  // const [UserOperations, setUserOperations] = useState([]);
  // const [UserOerationsCount, setUserOerationsCount] = useState();



  const historyPopUpTitle = <div style={{ textAlign: "center" }}>
    <div>{t("Details")}</div>
  </div>;
  const historyPopUpFooter = (
    <>

      <button
        className="footerPopUpBtns"
        onClick={() => setHistoryPopUp(false)}
      >
        {t("closeBtn")}
      </button>
    </>
  );
  const TransactionsShow = () => {
    settransactionsOpen(true);
    setAccountInfoOpen(false);

  }

  const OperatorsShow = () => {
    settransactionsOpen(false);
    setAccountInfoOpen(true);

  }

  useEffect(() => {
    if (!clientID)
      return;
    GetAccountInfo({
      "@clientID": clientID
    })
      .then((response) => {
        if (response.data.table1[0].PhoneNumber == null)
          setProfilePhoneNumber('-');
        else
          setProfilePhoneNumber(response.data.table1[0].PhoneNumber);
        if (response.data.table2[0].balance == null)
          setAccountSMSBalance(0);
        else
          setAccountSMSBalance(response.data.table2[0].balance);
      })

    GetAccountSenders({
      "@clientID": clientID,
    })
      .then((response) => {
        setSendersCount(response.data.table1.length)
      })
      .catch((err) => {
        toast.error(`${err}`);
      });
  }, [transactionsOpen, AccountInfoOpen, clientID]);



  const columns = useMemo(
    () => [

      {
        Header: t("userName"),
        accessor: "UserName",
      },
      {
        Header: t("date"),
        accessor: "date",
      },
      {
        Header: t("amount"),
        accessor: "Amount",
        Cell: ({ row: { original } }) => {
          return <CurrencyFormat value={original.Amount} displayType={'text'} thousandSeparator={true} />
        },
      },
      {
        Header: t("method"),
        accessor: "AdminName",
        Cell: ({ row: { original } }) => {
          if (original.AdminName === null)
            return "Online Payment";
          else
            return t(original.AdminName);
        },
      }
    ],
    [t]
  );


  // const columnsOperators = useMemo(
  //   () => [
  //     {
  //       Header: t("Operator"),
  //       accessor: "Operation",
  //     },
  //     {
  //       Header: t("Cost"),
  //       accessor: "Cost",
  //     }
  //   ],
  //   [t]
  // );
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoadingHistory(true);
      setTransactionHistory([]);
      setHistoryPageCount();
      GetUserCreditHistory({
        "@page": pageIndex,
        "@rowsPerPage": pageSize,
        "@clientID": clientID,
      })
        .then((response) => {
          setIsLoadingHistory(false);

          setTransactionHistory(response.data.table1);
          setHistoryPageCount(response.data.table2[0].Total);
        })
        .catch((error) => {
          setIsLoadingHistory(false);
          setIsLoadingHistory(false);
        });
    },
    [t, clientID]
  );

  // const fetchUserOperationData = useCallback(
  //   ({ pageSize, pageIndex }) => {
  //     setIsLoadingOperations(true);
  //     setUserOperations([]);
  //     setUserOerationsCount();
  //     GetAccountInfo({
  //       "@clientID": clientID
  //     })
  //       .then((response) => {
  //         setProfilePhoneNumber(response.data.table1[0].PhoneNumber);
  //       })

  //     // GetUserOperations({
  //     //   "@page": pageIndex,
  //     //   "@rowsPerPage": pageSize,
  //     //   "@clientID": clientID,
  //     // })
  //     //   .then((response) => {
  //     //     setIsLoadingOperations(false);
  //     //     setUserOperations(response.data.table1);
  //     //     setUserOerationsCount(response.data.table2[0].Total);
  //     //   })
  //     //   .catch((error) => {
  //     //     setIsLoadingOperations(false);
  //     //     setIsLoadingOperations(false);
  //     //   });
  //   },
  //   [t, clientID]
  // );



  return (
    <>

      <PopUp
        popUpTitle={historyPopUpTitle}
        children={
          <>
            <div className={`form-group  ${i18n.language}`}>
              <button className={transactionsOpen ? "TransactionBtn titlepopupActive" : "TransactionBtn"} onClick={() => TransactionsShow()} >{t("Transactions")}</button>
              <button className={AccountInfoOpen ? "TransactionBtn titlepopupActive" : "TransactionBtn"} onClick={() => OperatorsShow()} >{t("Account Information")}</button>
              {transactionsOpen &&
                <div className="transactionsTableContainer">
                  <Table
                    columns={columns}
                    data={transactionHistory}
                    fetchData={fetchData}
                    loading={isLoadingHistory}
                    TotalRows={historyPageCount}
                  />
                </div>
              }
              {AccountInfoOpen &&
                <div className="transactionsTableContainer">
                  {/* <Table
                    columns={columnsOperators}
                    data={UserOperations}
                    fetchData={fetchUserOperationData}
                    loading={isLoadingOperations}
                    TotalRows={UserOerationsCount}

                  /> */}

                  <table >
                    <thead>
                      <tr>

                        <th>
                          {t("phonenumber")}
                        </th>

                        <th>
                          {t("smsBalance")}
                        </th>
                        <th>
                          {t("sender")}
                        </th>

                      </tr>

                    </thead>
                    <tbody>
                      <tr>
                        <td>{profilePhoneNumber}</td>
                        <td><CurrencyFormat value={accountSMSBalance} displayType={'text'} thousandSeparator={true} /></td>
                        <td>{sendersCount}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              }
            </div>
          </>

        }
        popUpFooter={historyPopUpFooter}
        showPopUp={historyPopUp}
        closePopUp={() => setHistoryPopUp(false)}
      />
    </>
  );
}
