import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Header from "../../component/header";
import "./style.css";

export default function UpdateAccount() {
  const { t, i18n } = useTranslation();
  const [toggleSideBar] = useState(false);

  return (
    <>
      <Header
        title={t("termsAndConditions")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div className="termsAndConditionsDiv">
        <div className={`termsAndConditionsContent ${i18n.language}`}>
          <strong>{t("important")}: </strong>
          {t("importantContent")}
          <br />
          <strong>{t("usageInstructions")}</strong>
          <br />
          {t("usageInstructionsContent")}
          <br />
          <strong>{t("messageLength")}</strong>
          <br />
          {t("messageLengthContent")}
          <br />
          <strong>{t("operatingAgreement")}</strong>
          <br />
          {t("operatingAgreementContent")}
          <br />
          <strong>{t("importantNote")}</strong>
        </div>
      </div>
    </>
  );
}
