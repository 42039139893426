import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import "./style.css";

export default function Confirm() {
  return (
    <div className="center-screen">
      <Player
        style={{ width: "300px" }}
        src="./images/launching.json"
        className="player"
        loop
        autoplay
      />
      <div className="waitingScreenTitle">
        Your account will be <br /> launched soon !
      </div>
      <div className="waitingScreenSubTitle">
        But first we have to review your data ...
      </div>
    </div>
  );
}
