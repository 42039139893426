import React from "react";

export default function TextArea({
  inputFieldId,
  setOnChange,
  inputFieldValue,
  inputFieldType,
  isRequired,
  placeholder,
  classStyle,
}) {
  return (
    <textarea
      type={inputFieldType || "text"}
      className={classStyle}
      required={isRequired}
      id={inputFieldId}
      value={inputFieldValue}
      onChange={setOnChange}
      placeholder={placeholder}
    />
  );
}
