import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Field from "../../component/field";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import { UpdateUser, GetUser, GetUserPackages } from "../../API";
import "./style.css";

export default function UpdateAccount() {
  const [userPackages, setUserPackages] = useState([]);
  const [profileUserName, setProfileUserName] = useState();
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const [profilePhoneNumber, setProfilePhoneNumber] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { reload, setReload } = useContext(Context);
  const [isLoadingUserData, setIsLoadingUserData] = useState(false);
  const { t, i18n } = useTranslation();
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const mobileNumberRegex = /^01[0125][0-9]{8}$/;
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;

  useEffect(() => {
    setIsLoadingUserData(true);
    GetUser({ "@adminID": localStorage.getItem("userId") }).then((data) => {
      setIsLoadingUserData(false);
      setProfileUserName(data.data.table1[0].Username);
      setUserName(data.data.table1[0].Username)
      setProfilePhoneNumber(data.data.table1[0].PhoneNumber);
      setEmail(data.data.table1[0].Email);
    });

    GetUserPackages().then((data) => {
      setUserPackages(data.data.table1);
    });

  }, [reload])

  const updtaeUserData = () => {
    if (!emailRegex.test(email)) {
      toast.error("E-mail is invalid");
      setIsLoading(false);
      return;
    }
    if (!mobileNumberRegex.test(profilePhoneNumber)) {
      toast.error("Mobile number is invalid");

      setIsLoading(false);
      return;
    }
    if (!passwordRegex.test(newPassword) && (newPassword != "" && newPassword != null)) {
      toast.error(t("passwordInvalid"));
      setIsLoading(false);
      return;
    }

    if ((newPassword != "" && newPassword != null) && newPassword !== confirmPassword) {
      toast.error("Passwords do not match.");
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    UpdateUser({
      "@username": profileUserName,
      "@phoneNumber": profilePhoneNumber,
      "@password": newPassword || null,
      "@email": email,
    })
      .then((res) => {
        if (res.data.table1[0].Column1 == 1) {
          toast.error(t("usernameExists"));
          setIsLoading(false);
          return;
        }
        setReload(reload + 1);
        setIsLoading(false);
        toast.success("Data updated successfully");
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err);
      });
  };

  return (
    <div className="myProfileMainContetnt">
      <div className="UpdateAccountTitles"><div className="welcomeTitle">{t("welcomeUser")} {userName}</div><div className="subTitle">{t("profileDesc")}</div></div>
      {
        userPackages.map(packs =>
          <div className="subscriptionDetailes">

            <div className="title">
              {packs.Name} <span className="price">{packs.Cost} {t("currency")}</span>
            </div>
            <div className="subscriptionContent">
              <div>
                Total Amount : {packs.smsVolume}
                <br />
                Subscription Date :  {packs.packStartDate}
                <br />
                Expiration Date :  {packs.packEndDate}
                <br />
                Days Left for Renewal : {packs.remainingDays}
              </div>
              <img
                id="subscriptionImg"
                src="images/subscriptionImg.png"
                alt="subscription"
              />
            </div>

          </div>
        )
      }
      <div className="updateAccountDiv">
        <div className="updateAccountTitle"><i className="fas fa-user profileUser"></i><div>{t("PersonalInformation")}</div></div>
        {isLoadingUserData && <span className="spinner-border" role="status"></span>}
        <div className={`form-group ${i18n.language}`}>
          <div className="form-group">
            <Field
              inputFieldValue={profileUserName}
              setOnChange={setProfileUserName}
              placeholder={t("userName")}
              labelName={t("userName")}
              isDisabled={isLoadingUserData}
            />
          </div>
          <div className="form-group">
            <Field
              inputFieldValue={profilePhoneNumber}
              setOnChange={setProfilePhoneNumber}
              placeholder={t("phoneNumber")}
              labelName={t("phoneNumber")}
              isDisabled={isLoadingUserData}
            />
          </div>
          <div className="form-group">
            <Field
              inputFieldValue={email}
              setOnChange={setEmail}
              placeholder={t("email")}
              labelName={t("email")}
              isDisabled={isLoadingUserData}
            />
          </div>
          <div className="form-group">
            <Field
              inputFieldType={"password"}
              inputFieldValue={newPassword}
              setOnChange={setNewPassword}
              placeholder={t("password")}
              labelName={t("newPassword")}
              isDisabled={isLoadingUserData}
            />
          </div>
          <div className="form-group">
            <Field
              inputFieldType={"password"}
              inputFieldValue={confirmPassword}
              setOnChange={setConfirmPassword}
              placeholder={t("password")}
              labelName={t("confirmPassword")}
              isDisabled={isLoadingUserData}
            />
          </div>
        </div>
        <div>
          <button disabled={isLoading || isLoadingUserData} className="saveChangesBtn" onClick={() => updtaeUserData()}>
            {isLoading && (
              <span
                className="spinner-border"
                role="status"
              ></span>
            )}
            {t("saveChanges")}
          </button>
        </div>
      </div>
    </div>
  );
}
