import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import "./style.css";

function CircleChart({ chartData }) {
  const options = {
    plugins: {
      legend: {
        position: 'left',
        align: 'center',
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          usePointStyle: true,
          pointStyle: "rectRounded"
        },
      },
    },
  };

  return (

    <Doughnut data={chartData} options={options} />

  );
}

export default CircleChart;
