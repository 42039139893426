import React, { useEffect } from "react";
import { useTable, usePagination } from "react-table";
import { useTranslation } from "react-i18next";
import "./style.css";

const PAGE_SIZE = 10;

export default function Table({
  columns,
  data,
  fetchData,
  loading,
  TotalRows,
  noPagination,
  tableHeader
}) {
  const { t, i18n } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      pageCount: Math.ceil(TotalRows / PAGE_SIZE),
      initialState: { pageIndex: 0, pageSize: PAGE_SIZE },
      manualPagination: true,
    },
    usePagination
  );

  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  const pageNumbers = [];
  for (let i = 1; i <= pageOptions.length; i++) {
    pageNumbers.push(i);
  }
  return (
    <>
      <div className="tableComponantHeader">{tableHeader}</div>
      <table className="rounded-corners" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render("Header")}
                  {/* <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={`tableFooter ${i18n.language}`}>
        <div className="totalNumberOfPages">
          {loading ? (
            <div className="loadingDivStyle">
              <span className="spinner-border" role="status"></span>
            </div>
          ) : (
            pageNumbers == 0 && (
              <div className="loadingDivStyle">
                <div className="loadingStyle" colSpan="10000">
                  {/* No data to show */}
                </div>
              </div>
            )
          )}
        </div>
        {!noPagination && (<div className="pagination">
          {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button> */}
          <button
            className="nextPrevBtns"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {t("Prev")}
          </button>
          <span>
            <strong>
              {pageOptions.length < pageIndex + 1 ? pageOptions.length : pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>


          <button
            className="nextPrevBtns"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {t("Next")}
          </button>
          {/* <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button> */}
        </div>)}
        <div></div>
      </div>
    </>
  );
}
