import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { GetScheduleSMSReport, GetSenders } from "../../API";
import { toast } from "react-toastify";
import TableDatePicker from "../../component/datePicker";
import Table from "../../component/newTable";
import ConfirmToDelete from "../../component/confirmToDelete";
import EditSendDate from "../../component/editDateSchedule";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import Header from "../../component/header";
import DateRangePicker from "../../component/dateRangePicker";
import "./style.css";

export default function ScheduleSMSReport() {
  const { role, reload, startDate, endDate } = useContext(Context);
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const [data, setData] = useState([]);
  const [senderList, setSenderList] = useState([]);
  const [selectedSender, setSelectedSender] = useState();
  // const [startDate, setStartDate] = useState(firstDay);
  // const [endDate, setEndDate] = useState(lastDay);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showEditeSendDate, setShowEditSendDate] = useState(false);
  const [ID, setID] = useState();
  const [campaignName, setCampaignName] = useState();
  const [toggleSideBar] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    GetSenders().then((response) => {
      setSenderList(response.data.table1);
      if (response.data.table1.length != 0) {
        setSelectedSender(response.data.table1[0].ID);
      }
    });
  }, [t, role]);

  const columns = useMemo(
    () => [
      {
        Header: t("date"),
        accessor: "SendDate",
      },
      {
        Header: t("time"),
        accessor: "SendTime",
      },
      {
        Header: t("campaignName"),
        accessor: "CampaignName",
      },
      {
        Header: t("count"),
        accessor: "RowsCount",
      },
      {
        Header: t("sender"),
        accessor: "Sender",
      },
      {
        Header: "",
        accessor: "groupFilePath",
        Cell: ({ row: { original } }) => {
          const sendDateTime = new Date(original.SendDate + " " + original.SendTime).getTime();
          const currentTime = new Date().getTime();
          const oneHourFromNow = new Date(currentTime + 60 * 60 * 1000);

          if (sendDateTime >= oneHourFromNow) {
            return (
              <div className="contactsActions">
                <i
                  title="Edit Date"
                  className="fas fa-pen editIconStyle"
                  onClick={() => handleShowEdit(original.ID)}
                ></i>
                <i
                  title="Delete file"
                  className="fas fa-trash deleteIconStyle"
                  onClick={() => deleteFile(original.ID, original.CampaignName)}
                ></i>
              </div>
            );
          } else {
            return null; // Return null to hide the actions
          }
        },
      },
    ],
    [t]
  );



  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      setData([]);
      GetScheduleSMSReport({
        "@page": pageIndex,
        "@rowsPerPage": pageSize,
        "@startDate": new Intl.DateTimeFormat("en").format(startDate),
        "@endDate": new Intl.DateTimeFormat("en").format(endDate),
        "@sender": selectedSender,
      })
        .then((response) => {
          setIsLoading(false);

          setData(response.data.table1);
          setPageCount(response.data.table2[0].Total);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [startDate, endDate, selectedSender, reload]
  );

  const handleShowEdit = (id) => {
    setID(id);
    setShowEditSendDate(true);
  };

  const deleteFile = (id, name) => {
    setID(id);
    setCampaignName(name);
    setShowConfirmDelete(true);
  };

  return (
    <div className="scheduleContainer">
      <Header
        title={t("scheduledCampaigns")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div className="tableMainTitles">
        <DateRangePicker />
        <select
          className="form-select filterList"
          value={selectedSender}
          onChange={(e) => setSelectedSender(e.target.value)}
        >
          <option value="-1">{t("allSender")}</option>
          {senderList &&
            senderList.map((s, i) => (
              <option value={s.ID} key={i}>
                {s.Name}
              </option>
            ))}
        </select>
      </div>
      <div className="tableDiv">
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={isLoading}
          TotalRows={pageCount}
          tableHeader={t("scheduledCampaigns")}
        />
      </div>

      <ConfirmToDelete
        openPopUp={showConfirmDelete}
        closePopUp={() => setShowConfirmDelete(false)}
        id={ID}
        campaign={campaignName}
      />
      <EditSendDate
        openPopUp={showEditeSendDate}
        closePopUp={() => setShowEditSendDate(false)}
        id={ID}
      />
    </div>
  );
}
