import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Context } from "../../context";
import { useTranslation } from "react-i18next";
import Field from "../../component/field";
import Select from "react-select";
import TextArea from "../../component/textArea";
import "./style.css";
import axios from "axios";
import CopyToClipboardButton from "./CopyToClipboardButton";
import Tooltip from "@material-ui/core/Tooltip";
import Header from "../../component/header";
import {
  GetattemptsCount,
  UpdateattemptsCount,
  GetClientsSenders,
  GetClientOperators,
} from "../../API";

export default function Reports() {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumbersList, setPhoneNumbersList] = useState("");
  const [phoneNumbersListUpdate, setPhoneNumbersListUpdate] = useState("");
  const { reload, setReload } = useContext(Context);
  const [message, setMessage] = useState("");
  const [isOneToOneApi, setIsOneToOneApi] = useState(true);
  const [requestID, setRequestID] = useState("");
  const [senderList, setSenderList] = useState([]);
  const [selectedSender, setSelectedSender] = useState(null);
  const [operatorList, setOperatorList] = useState([]);
  const [selectedOperatorID, setSelectedOperatorID] = useState(null);
  const [attemptsCount, setAttemptsCount] = useState(0);
  const [toggleSideBar] = useState(false);
  const handleOnChangePhoneNumbersListArea = (e) => {
    const inputValue = e.target.value;
    const regex = /^[0-9\n]*$/;

    if (!regex.test(inputValue)) {
      return;
    }

    const lines = inputValue.split('\n');
    const formattedLines = lines.map(line => {
      let formattedLine = '';
      for (let i = 0; i < line.length; i++) {
        formattedLine += line[i];
        if ((i + 1) % 12 === 0 && i !== line.length - 1) {
          formattedLine += '\n';
        }
      }
      return formattedLine;
    });

    const formattedInputValue = formattedLines.join('\n');

    const phoneNumbersList = lines
      .map(line => line.trim())
      .filter(line => line.length > 0);

    setPhoneNumbersList(formattedInputValue);
    setPhoneNumbersListUpdate(phoneNumbersList);

  };


  const GenerateApiKeyURL =
    "https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/GenerateApiKey";
  const OneToOneSMSIntegrate =
    "https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/ForwardSMS";
  const BulkSMSIntegrate =
    "https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/ForwardBulkSMS";
  const Integration =
    "https://hub.advansystelecom.com/generalapiv12/BulkSMS/Integration.pdf";
  const IntegrationBulkSms =
    "https://hub.advansystelecom.com/generalapiv12/BulkSMS/IntegrationBulkSms.pdf";
  const [ApiKey, setApiKey] = useState(null);

  useEffect(() => {
    GetattemptsCount().then((res) => {
      setAttemptsCount(res.data.table1[0].TestLimit);
      setApiKey(res.data.table1[0].IntegrationKey)
    });
  }, []);

  useEffect(() => {
    GetClientsSenders({
      "@ClientID": localStorage.getItem("userId"),
    })
      .then((response) => {
        setSenderList(response.data.table1);
      })
      .catch((error) => {
        console.error(error);
      });

    GetClientOperators({
      "@adminID": localStorage.getItem("userId"),
    }).then((response) => {
      setOperatorList(response.data.table1);
    });
  }, [t]);

  const downloadDoc = () => {
    isOneToOneApi ? window.open(Integration, "_blank") : window.open(IntegrationBulkSms, "_blank");

  };

  const GenerateApiKey = () => {
    axios
      .post(
        GenerateApiKeyURL,
        {
          ConnectionStringName: "AdvansysBulk",
          StoredProcedureName: "Dashboard.UpdateApiIntergration",
          SpParams: {},
        },
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((ress) => {
        setApiKey(ress.data);
        console.log(ress);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendTestOneToOneSMSIntegrate = () => {
    if (selectedSender === null) {
      toast.error(t("errorSender"));
      return;
    }
    const operatorID = selectedOperatorID ? selectedOperatorID.value : 0;
    const phoneNumberEdit = /^01[0125][0-9]{8}$/.test(phoneNumber) ? `2${phoneNumber}` : phoneNumber

    axios
      .post(
        OneToOneSMSIntegrate,
        {
          PhoneNumber: phoneNumberEdit,
          Message: message,
          SenderName: selectedSender.label,
          RequestID: requestID,
          OperatorID: operatorID
        },
        {
          headers: {
            Authorization: ApiKey,
            Testing: 1,
          },
        }
      )
      .then((ress) => {
        if (ress.status === 200) {
          setAttemptsCount(attemptsCount - 1);
          toast.success("Success");
          setReload(!reload);
        } else {
          toast.error(ress.data);
          setReload(!reload);
        }
      })
      .catch((res) => {
        if (res.response.data.Message.includes("Exception")) {
          toast.error("something went wrong , Try again");
          return;
        }
        toast.error(res.response.data.Message);
      });
  };
  const sendTestBulkSMSIntegrate = () => {
    if (selectedSender === null) {
      toast.error(t("errorSender"));
      return;
    }
    axios
      .post(
        BulkSMSIntegrate,
        {
          PhoneNumbers: phoneNumbersListUpdate,
          Message: message,
          SenderName: selectedSender.label,
          RequestID: requestID
        },
        {
          headers: {
            Authorization: ApiKey,
            Testing: 1,
          },
        }
      )
      .then((ress) => {
        if (ress.status === 200) {
          setAttemptsCount(attemptsCount - 1);
          toast.success("Success");
          setReload(!reload);
        } else {
          toast.error(ress.data);
          setReload(!reload);
        }
      })
      .catch((res) => {
        if (res.response.data.Message.includes("Exception")) {
          toast.error("something went wrong , Try again");
          return;
        }
        toast.error(res.response.data.Message);
      });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "16px",
      width: "200px",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      background: state.isSelected ? "blue" : "white",
      fontSize: "14px",
    }),
  };

  return (
    <div className="apiContainer">
      <Header
        title={t("apiIntegration")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />

      <div className="apiIntegrationMainDiv">
        <div className="helpBtnDiv">
          <div className="apis-types">
            <div className="api-types-radion-container">
              <div className="apis-types-title">{t("APITypes")}</div>
              <div className="d-flex">
                <div>
                  <input
                    type="radio"
                    id="oneToOneApi"
                    value="manual"
                    checked={isOneToOneApi}
                    onChange={() => { setIsOneToOneApi(true); }}
                  />
                  <label htmlFor="oneToOneApi">{t("oneToOneApi")}</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="bulkApi"
                    value="file"
                    checked={!isOneToOneApi}
                    onChange={() => { setIsOneToOneApi(false); }}
                  />
                  <label htmlFor="bulkApi">{t("bulkSmsApi")}</label>
                </div>
              </div>

            </div>

          </div>
          <button className="apiHelpBtnsStyle" onClick={() => downloadDoc()}>
            <i className="fas fa-info-circle"></i>
            {t("help")}
          </button>

        </div>

        {isOneToOneApi ? <>
          <div className="apiIntegrationTitle">
            {t("apiIntegration")} :{" "}
            <span className="apilink">
              https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/ForwardSMS{" "}
            </span>
          </div>
          <div className="apiIntegrationCodeArea">
            {"{"}
            <br />
            <div className="integrationData">
              <div className="title">"{t("SenderName")}":</div>
              <Select
                className="form-select"
                value={selectedSender}
                onChange={setSelectedSender}
                styles={customStyles}
                options={senderList.map((s) => ({
                  value: s.ID,
                  label: s.Name,
                }))}
                placeholder={t("selectSender")}
              />
            </div>
            <div className="integrationData">
              <div className="title">"{t("OperatorID")}":</div>

              <Select
                className="form-select"
                value={selectedOperatorID}
                onChange={setSelectedOperatorID}
                styles={customStyles}
                options={operatorList.map((o) => ({
                  value: o.ID,
                  label: o.Name == "Mobinil" ? "Orange" : o.Name,
                }))}
                placeholder={t("selectOperator")}
              />
              <span className="optionalData">{t("optional")}</span>
            </div>
            <div className="integrationData">
              <div className="title">"{t("RequestID")}":</div>
              <Field
                inputFieldValue={requestID}
                setOnChange={setRequestID}
                inputFieldType="number"
              />
            </div>

            <div className="integrationData">
              <div className="title">"{t("PhoneNumber")}":</div>
              <Field
                inputFieldValue={phoneNumber}
                setOnChange={setPhoneNumber}
                inputFieldType="number"
              />
            </div>
            <div className="integrationData">
              <div className="title">"{t("Message")}":</div>
              <Field inputFieldValue={message} setOnChange={setMessage} />
            </div>

            {"}"}
          </div>
        </> : <>
          <div className="apiIntegrationTitle">
            {t("apiIntegration")} :{" "}
            <span className="apilink">
              https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/ForwardBulkSMS{" "}
            </span>
          </div>
          <div className="apiIntegrationCodeArea">
            {"{"}
            <br />
            <div className="integrationData">
              <div className="title">"{t("SenderName")}":</div>
              <Select
                className="form-select"
                value={selectedSender}
                onChange={setSelectedSender}
                styles={customStyles}
                options={senderList.map((s) => ({
                  value: s.ID,
                  label: s.Name,
                }))}
                placeholder={t("selectSender")}
              />
            </div>
            <div className="integrationData">
              <div className="title">"{t("RequestID")}":</div>
              <Field
                inputFieldValue={requestID}
                setOnChange={setRequestID}
                inputFieldType="number"
              />
            </div>

            <div className="integrationData">
              <div className="title">"{t("PhoneNumbers")}":      <Tooltip title={t("phoneNumbersGuide")} placement="top">
                <i className="fas fa-info-circle ml-1 mr-1"></i>
              </Tooltip></div>

              <TextArea
                classStyle={"phoneNumberListApi"}
                inputFieldValue={phoneNumbersList}
                setOnChange={(e) => handleOnChangePhoneNumbersListArea(e)}
                placeholder={t("phoneNumbersList")}
                inputFieldType={"number"}
              />
            </div>
            <div className="integrationData">
              <div className="title">"{t("Message")}":</div>
              <Field inputFieldValue={message} setOnChange={setMessage} />
            </div>

            {"}"}
          </div>
        </>}
        <div className="apiIntegrationBtns">
          <button className="apiBtnsStyle" onClick={() => GenerateApiKey()}>
            {t("Generate Key")}
          </button>
          {ApiKey != null && (
            <button
              className="apiTestBtnsStyle"
              onClick={isOneToOneApi ? () => sendTestOneToOneSMSIntegrate() : () => sendTestBulkSMSIntegrate()}
            >
              {t("Test")}
            </button>
          )}
        </div>

        {ApiKey != null && (
          <div className="integrationKey">
            {"API-Key : " + ApiKey + "  "}

            <CopyToClipboardButton text={ApiKey} />
          </div>
        )}
      </div>
    </div>
  );
}
