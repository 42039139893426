import React, { useState, useContext, useMemo, useCallback } from "react";
import { GetPackages, CreateTransaction } from "../../API";
import PopUp from "../../component/popUp";
import Table from "../../component/newTable";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import "./style.css";


export default function SmsBalance({
  openSmsBalancePopUp,
  closeSmsBalancePopUp,
}) {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [isLoadingBtn, setIsLoadingBtn] = useState(true);
  const { reload, setReload } = useContext(Context);

  // const [selected, setSelected] = useState(0);

  // const handleChange = event => {
  //   setSelected(event.target.value);
  //   setTimeout(() => {
  //     console.log(selected);
  //   }, 5000);
  // }
  var date = new Date();
  const openUrl = (url) => {
    window.open(url, "_blank");
    closeSmsBalancePopUp();
  };
  const executeTransaction = (cost, packageID) => {
    CreateTransaction({
      "@clientID": localStorage.getItem("userId"),
      "@amount": cost,
      "@packageID": packageID,
      "@paymentMethod": 2
    })
      .then((response) => {
        if (response.data.table1[0]?.limitExceeded == 1) {
          toast.error(t("limitExceededErr"));
          return;
        }
        if (response.data.table1[0].Column1 == 1) {
          openUrl(
            `https://pay.advansystelecom.com/TMEPayments/RedirectTo?serviceID=9&serviceGatewayID=16&inquireInfo=${response.data.table2[0].transactionID
            }&merchantRefNo=${response.data.table2[0].transactionID
            }${date.getSeconds()}&mobileNumber=${response.data.table2[0].PhoneNumber || "01"
            }&amount=${cost}&view=Fawry`
          );
        } else if (response.data.table1[0].Column1 == 2) {
          closeSmsBalancePopUp();
          setReload(!reload);
        }
        else if (response.data.table1[0].Column1 == 0) {
          closeSmsBalancePopUp();
          toast.error(t("RechargeErr"));
        }

      })
      .catch((error) => {
        //     toast.error("Error Occure");
        //     console.log(error);
        //     setIsLoadingBtn(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: t("packName"),
        accessor: "Name",
      },
      {
        Header: t("packVolume"),
        accessor: "SmsVolume",
      },
      {
        Header: t("packCost"),
        accessor: "Cost",
        Cell: ({ row: { original } }) => {
          return <div>
            {original.Cost}
          </div>;
        },
      },
      {
        Header: "",
        accessor: "ID",
        Cell: ({ row: { original } }) => {
          return <div className="transactionReportActions"><button className="addBalanceBtn"
            onClick={() => executeTransaction(original.Cost, original.ID)}>
            {t("subscribe")}</button>
          </div>;
        },
      },

    ],
    [t]
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {

      setIsLoadingBtn(true);
      setData([]);
      GetPackages().then((res) => {
        setData(res.data.data.Table);
        setIsLoadingBtn(false);
      });
    },
    [reload]
  );

  const popUpTitle = (
    <div style={{ textAlign: "center" }}>
      <img
        id="packagesImg"
        src="images/smsPackagesIcon.png"
        alt="Sms Packages" />
      <div>{t("selectYourPackage")}</div>
    </div>
  );

  const popUpFooter = (
    <div>
      <button
        className="closeBtn"
        onClick={closeSmsBalancePopUp}>
        {t("closeBtn")}
      </button>
    </div>
  );

  return (
    <>
      <PopUp
        popUpTitle={popUpTitle}
        popUpFooter={popUpFooter}
        showPopUp={openSmsBalancePopUp}
        closePopUp={closeSmsBalancePopUp}
      >
        <div className={`packList ${i18n.language}`}>
          <Table
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={isLoadingBtn}
            TotalRows={10}
          />
        </div>

        <div>
          {/* <FormControl>
            <FormLabel >Payment</FormLabel>
            <RadioGroup
              row
              onChange={handleChange}

            >
              <FormControlLabel value="1" control={<Radio className="radioBtnStyle" />} label={<div className="paymentMethodDiv">
                <img
                  src="images/money.png"
                  alt="money icon"
                />My Wallet</div>} />

              <FormControlLabel value="2" control={<Radio className="radioBtnStyle" />} label={<div className="paymentMethodDiv">
                <img

                  src="images/credit-card.png"
                  alt="credit card icon"
                />
                Online Payment</div>} />
            </RadioGroup>
          </FormControl> */}
          {/* <div>
            <input type="radio" name="test" value="1" onChange={(e) => setSelected(e.target.value)} defaultChecked={true} />
            <input type="radio" name="test" value="2" onChange={(e) => setSelected(e.target.value)} />
          </div> */}
        </div>

      </PopUp>
    </>
  );
}
