import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { GetDLR, GetDLRStatistics } from "../../API";
import { toast } from "react-toastify";
import Table from "../../component/newTable";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import CampaignDetailsPopUp from "../../component/CampaignDetailsPopUp";
import CircleChart from "../../component/circleChart";
import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend } from "chart.js";
import { useParams } from "react-router-dom";
import Header from "../../component/header";
import "./style.css";

export default function DLRTable() {
  const [reload, setReload] = useState(false);
  const { reloadCredit, toggleCredit } = useContext(Context);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [StatuesSent, setStatuesSent] = useState(null);
  const [StatuesDelivered, setStatuesDelivered] = useState(null);
  const [StatuesUndelivered, setStatuesUndelivered] = useState(null);
  const [StatuesFailed, setStatuesFailed] = useState(null);
  const [StatuesTotal, setStatuesTotal] = useState(null);
  const [campaignID, setcampaignID] = useState(null);
  const { t } = useTranslation();
  const [toggleSideBar] = useState(false);
  const { ID } = useParams();

  ChartJs.register(Tooltip, Title, ArcElement, Legend);

  const data2 = {
    labels: [t("Delivered"), t("Undelivered"), t("Failed"), t("Sent")],
    datasets: [
      {
        data: [30, 40, 30, 90],
        backgroundColor: ["#13B369", "#606060", "#FFCACA", "#6BB8FF"],
      },
    ],
  };
  useEffect(() => {
    setIsLoadingChart(true);
    GetDLRStatistics({
      "@campaignID": ID,
    })
      .then((response) => {
        setStatuesDelivered(response.data.table1[0].Delivered);
        setStatuesUndelivered(response.data.table1[0].Undelivered);
        setStatuesFailed(response.data.table1[0].Failed);
        setStatuesSent(response.data.table1[0].Sent);
        setStatuesTotal(
          response.data.table1[0].Delivered +
            response.data.table1[0].Undelivered +
            response.data.table1[0].Failed +
            response.data.table1[0].Sent
        );
        const data = response.data.table1[0];
        const chartData = {
          labels: [t("Delivered"), t("Undelivered"), t("Failed"), t("Sent")],
          datasets: [
            {
              data: [data.Delivered, data.Undelivered, data.Failed, data.Sent],
              backgroundColor: ["#13B369", "#606060", "#FFCACA", "#6BB8FF"],
            },
          ],
        };
        setChartData(chartData);
        setIsLoadingChart(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [t, ID]);

  const columns = useMemo(
    () => [
      {
        Header: t("Receiver"),
        accessor: "Receiver",
      },
      {
        Header: t("sender"),
        accessor: "Sender",
      },
      {
        Header: t("SendID"),
        accessor: "SendID",
      },
      {
        Header: t("Statues"),
        accessor: "Statues",
      },
      {
        Header: t("requestDate"),
        accessor: "requestDate",
      },
      {
        Header: t("DLRdate"),
        accessor: "date",
      },
    ],
    [t, reload, reloadCredit, ID]
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      setData([]);
      GetDLR({
        "@page": pageIndex,
        "@rowsPerPage": pageSize,
        "@campaignID": ID,
      })
        .then((response) => {
          setIsLoading(false);
          setData(response.data.table1);
          setPageCount(response.data.table2[0].Total);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [t, reload, reloadCredit, ID]
  );

  return (
    <div className="dlrContainer">
      <Header
        title={t("DLRReport")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />

      <div className="dlrNumbers">
        <div className="statisticsDlrNumbersDiv">
          <div className="dlrStatusCard">
            <div className="statusCardStyle ">
              <div className="statusCardBody">
                <div className="statusDelvIconDiv">
                  <img
                    className="statuesIcon"
                    src="images/Delivered.png"
                    alt="delivered icon"
                  />
                </div>

                <div>
                  <div className="statusCardValue">
                    {isLoading && (
                      <span className="spinner-border" role="status"></span>
                    )}
                    {!isLoading && StatuesDelivered}
                  </div>

                  <div className="statusCardTitle">{t("Delivered")}</div>
                </div>
              </div>
            </div>

            <div className="statusCardStyle ">
              <div className="statusCardBody">
                <div className="statusSendIconDiv">
                  <img
                    className="statuesIcon"
                    src="images/Sent.png"
                    alt="delivered icon"
                  />
                </div>

                <div>
                  <div className="statusCardValue">
                    {isLoading && (
                      <span className="spinner-border" role="status"></span>
                    )}
                    {!isLoading && StatuesSent}
                  </div>

                  <div className="statusCardTitle">{t("Sent")}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="dlrStatusCard">
            <div className="statusCardStyle ">
              <div className="statusCardBody">
                <div className="statusSendIconDiv">
                  <img
                    className="statuesIcon"
                    src="images/Undelivered.png"
                    alt="undelivered icon"
                  />
                </div>

                <div>
                  <div className="statusCardValue">
                    {isLoading && (
                      <span className="spinner-border" role="status"></span>
                    )}
                    {!isLoading && StatuesUndelivered}
                  </div>

                  <div className="statusCardTitle">{t("Undelivered")}</div>
                </div>
              </div>
            </div>

            <div className="statusCardStyle ">
              <div className="statusCardBody">
                <div className="statusFailedIconDiv">
                  <img
                    className="statuesIcon"
                    src="images/Faild.png"
                    alt="delivered icon"
                  />
                </div>

                <div>
                  <div className="statusCardValue">
                    {isLoading && (
                      <span className="spinner-border" role="status"></span>
                    )}
                    {!isLoading && StatuesFailed}
                  </div>

                  <div className="statusCardTitle">{t("Failed")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="chartCard">
          <div className="ChartDoughnut">
            <div>
              <div className="chartCardTitle">{t("AllSMS")}</div>
              <div className="chartCardTotal">{StatuesTotal}</div>
            </div>
            <div className="chartcardDiv">
              {chartData && <CircleChart chartData={chartData} />}
            </div>
          </div>
        </div>
      </div>
      <div className="tableDiv">
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={isLoading}
          TotalRows={pageCount}
        />
      </div>
      <CampaignDetailsPopUp campaignID={campaignID} />
    </div>
  );
}
