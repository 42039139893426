import React, { useContext, useState } from "react";
import { DeleteScheduleSMS } from "../../API";
import PopUp from "../../component/popUp";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import "./style.css";
export default function ConfirmToDelete({
  openPopUp,
  closePopUp,
  campaign,
  id,
}) {
  const { t } = useTranslation();
  const { reload, setReload } = useContext(Context);
  const [isLoadingConfirm, setIsloadingConfirm] = useState(false);

  const deleteCampaign = (id) => {
    setIsloadingConfirm(true);
    DeleteScheduleSMS({
      "@id": id,
    })
      .then((response) => {
        setIsloadingConfirm(false);
        setReload(!reload);
        closePopUp();
        toast.success(`${t("campaignDeletedsuccessfully")}`);
      })
      .catch((error) => {
        setIsloadingConfirm(false);
        toast.error(error);
        console.log(error);
      });
  };


  const popUpTitle = (
    <div style={{ textAlign: "center" }}>
      <img
        id="deleteImg"
        src="images/delete.png"
        alt="Transaction amount"
      />
    </div>
  );

  const popUpFooter = (
    <div className="mb-4">
      <button
        className="footerConfirmPopUpBtnsCancel"
        onClick={closePopUp}
      >
        {t("closeBtn")}
      </button>
      <button
        className="footerConfirmPopUpBtnsOk"
        disabled={isLoadingConfirm}
        onClick={() => deleteCampaign(id)}
      >
        {t("ok")}
        {isLoadingConfirm && (
          <span className="spinner-border" role="status"></span>
        )}
      </button>
    </div>
  );

  return (
    <>
      <PopUp
        popUpTitle={popUpTitle}
        popUpFooter={popUpFooter}
        showPopUp={openPopUp}
        closePopUp={closePopUp}
      >
        <div className="confirmQuestion">
          Are You Sure To Delete {campaign} Campaign?
        </div>

        <div>
        </div>

      </PopUp>
    </>
  );
}
