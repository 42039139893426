import React, { useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Provider from "../context";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";
import "./style.css"

const App = () => {
  useEffect(() => {
    if (window.location.href.includes("portal.sms")) {
      document.documentElement.style.setProperty('--main-color', '#DE122A');
      document.documentElement.style.setProperty('--second-color', '#555757');
      // document.documentElement.style.setProperty('--chart-color', '#1338ba');
      // document.documentElement.style.setProperty('--hover-color', 'rgba(0, 0, 255, 0.1)');
    }
  }, []);
  return (
    <Provider>
      <Router>
        <Routes />
      </Router>
      <ToastContainer />
    </Provider>
  );
};

export default App;
