import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

function BarChart({ chartData }) {
  const lineChartOptions = {
    plugins: {
      legend: {
        display: false, // Set to false to hide the legend
      },
    },
    maintainAspectRatio: false, // Set to false to allow 100% width and height
    responsive: true,
  };

  return <Line data={chartData} options={lineChartOptions} />;
}

export default BarChart;
