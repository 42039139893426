import React, { useState, useContext, useEffect } from "react";
import { SignUp, GetBusinessSector } from "../../API";
import { toast } from "react-toastify";
import PopUp from "../popUp";
import { Context } from "../../context";
import { useTranslation } from "react-i18next";
import Field from "../../component/field";
import Select from "react-select";
import axios from "axios";
import Table from "../../component/newTable";
import i18next from "i18next";
import "./style.css";
export default function CreateNewAccountPopUp() {
  const { createUserPopUp, setCreateUserPopUp } = useContext(Context);
  const { t, i18n } = useTranslation();
  const [balance, setBalance] = useState();
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const hasEPush = window.location.href.includes("portal.sms");
  const parentID = hasEPush ? "402" : null;
  const [selectedGovernment, setSelectedGovernment,] = useState(null);
  const [selectedReligions, setSelectedReligions,] = useState(null);
  const [selectedBusinessSectors, setSelectedBusinessSectors,] = useState(null);
  const [salesName, setSalesName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [taxIdNo, setTaxIdNo] = useState("");
  const [crNo, setCrNo] = useState("");
  const [dateOfEstablishment, setDateOfEstablishment] = useState("");
  const [address, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [contactName, setContactName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [birthday, setBirthday] = useState("");
  const [businessSectors, setBusinessSectors] = useState([]);
  const governments = [
    { id: 1, name: 'القاهرة' },
    { id: 2, name: 'الإسكندرية' },
    { id: 3, name: 'الجيزة' },
    { id: 4, name: 'الدقهلية' },
    { id: 5, name: 'البحر الأحمر' },
    { id: 6, name: 'البحيرة' },
    { id: 7, name: 'الفيوم' },
    { id: 8, name: 'الغربية' },
    { id: 9, name: 'الإسماعيلية' },
    { id: 10, name: 'المنوفية' },
    { id: 11, name: 'المنيا' },
    { id: 12, name: 'القليوبية' },
    { id: 13, name: 'الوادي الجديد' },
    { id: 14, name: 'السويس' },
    { id: 15, name: 'أسوان' },
    { id: 16, name: 'أسيوط' },
    { id: 17, name: 'بني سويف' },
    { id: 18, name: 'بورسعيد' },
    { id: 19, name: 'دمياط' },
    { id: 20, name: 'الشرقية' },
    { id: 21, name: 'جنوب سيناء' },
    { id: 22, name: 'كفر الشيخ' },
    { id: 23, name: 'مطروح' },
    { id: 24, name: 'الأقصر' },
    { id: 25, name: 'قنا' },
    { id: 26, name: 'شمال سيناء' },
    { id: 27, name: 'سوهاج' }
  ];
  const religions = [{ id: 1, name: 'مسلم' }, { id: 2, name: 'مسيحي' }];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "16px",
      width: "100%",
      height: "48px"
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      background: state.isSelected ? "blue" : "white",
      fontSize: "14px",
    }),
  };

  useEffect(() => {
    GetBusinessSector().then((response) => {
      setBusinessSectors(response.data.table1);
    });
  }, [t]);



  const handleSubmit = () => {

    if (!salesName.trim() || !companyName.trim() || !taxIdNo.trim() || !crNo.trim() || !dateOfEstablishment.trim() ||
      !address.trim() || !area.trim() || !userName.trim() || !password.trim() || !contactName.trim() ||
      !mobile.trim() || !email.trim() || !birthday.trim() || !selectedGovernment || !selectedBusinessSectors ||
      !selectedReligions) {
      toast.error(t("fillAllFields"));
      return;
    }

    SignUp({
      "@email": email,
      "@username": userName,
      "@password": password,
      "@phoneNumber": mobile,
      "@taxNumber": taxIdNo,
      "@CommercialRegister": crNo,
      "@parentID": localStorage.getItem("userId"),
      "@SalesName": salesName,
      "@CompanyName": companyName,
      "@DateOfEstablishment": dateOfEstablishment,
      "@BirthDate": birthday,
      "@ContactPerson": contactName,
      "@Address": address,
      "@Area": area,
      "@Government": selectedGovernment.value,
      "@BusinessSector": selectedBusinessSectors.value,
      "@Religion": selectedReligions.value
    })
      .then((response) => {
        if (response.data.data.Table[0].Column1 == 1) {
          toast.error(t("usernameExists"));
          setIsLoading(false);
          return;
        }
        if (response.data.data.Table[0].Column1 == 2) {
          toast.error(t("emailExists"));
          setIsLoading(false);
          return;
        }

        setCreateUserPopUp(false);
        toast.success(t("accountCreatedSuccessfully"));
        setSalesName("");
        setCompanyName("");
        setTaxIdNo("");
        setCrNo("");
        setDateOfEstablishment("");
        setAddress("");
        setArea("");
        setUserName("");
        setPassword("");
        setContactName("");
        setMobile("");
        setEmail("");
        setBirthday("");
        setSelectedGovernment(null);
        setSelectedBusinessSectors(null);
        setSelectedReligions(null);
        // axios
        //   .post(
        //     "https://hub.advansystelecom.com/generalapiv11/api/bulkSMS/SendEmail",
        //     { Email: email }
        //   )
        //   .then((res) => {
        //     if (res.status === 200) {
        //       setIsLoading(false);
        //     }
        //   })
        //   .catch((error) => {
        //     setIsLoading(false);
        //   });
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    setCreateUserPopUp(false)
    setBalance("");
  };
  const popUpTitle = <div style={{ textAlign: "center" }}>
    <div>{t("CreateNewAccount")}</div>
  </div>;

  const popUpFooter = (
    <>
      <button className="footerPopUpBtns" onClick={() => handleSubmit()}>{t('create')}</button>

      <button
        className="closeBtn"
        onClick={handleClose}
        disabled={isLoadingUpdate}
      >
        {t("closeBtn")}
      </button>
    </>
  );
  return (
    <>
      <PopUp
        popUpTitle={popUpTitle}
        children={<div className={`form-group ${t.language}`}>
          <div className="row mb-2">
            <div className="col-md-6 col-12">

              <Field
                inputFieldId={"updateUserSalesName"}
                inputFieldType={"text"}
                labelName={t("SalesName")}
                placeholder={t("SalesName")}
                inputFieldValue={salesName}
                setOnChange={(value) => setSalesName(value)}
              />
            </div>
            <div className="col-md-6 col-12">
              <Field
                inputFieldId={"updateUserCompanyName"}
                inputFieldType={"text"}
                labelName={t("CompanyName")}
                placeholder={t("CompanyName")}
                inputFieldValue={companyName}
                setOnChange={(value) => setCompanyName(value)}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-6 col-12">

              <Field
                inputFieldId={"updateUserTaxIdNo"}
                inputFieldType={"text"}
                labelName={t("taxNumber")}
                placeholder={t("taxNumber")}
                inputFieldValue={taxIdNo}
                setOnChange={(value) => setTaxIdNo(value)}
              />
            </div>
            <div className="col-md-6 col-12">
              <Field
                inputFieldId={"updateUserCrNo"}
                inputFieldType={"text"}
                labelName={t("CRNum")}
                placeholder={t("CRNum")}
                inputFieldValue={crNo}
                setOnChange={(value) => setCrNo(value)}
              />
            </div>
          </div>


          <div className="row mb-2">
            <div className="col-md-6 col-12">

              <Field
                inputFieldId={"updateUserDateOfEstablishment"}
                inputFieldType={"date"}
                labelName={t("DateOfEstablishment")}
                inputFieldValue={dateOfEstablishment}
                setOnChange={(value) => setDateOfEstablishment(value)}
              />
            </div>
            <div className="col-md-6 col-12">
              <div>
                <label className={`listLabelStyle ${i18next.language}`} htmlFor="businessSector">{t("BusinessSector")}</label>
                <Select
                  className="form-select"
                  value={selectedBusinessSectors}
                  onChange={setSelectedBusinessSectors}
                  styles={customStyles}
                  options={businessSectors.map((b) => ({
                    value: b.BusinessSectorName,
                    label: b.BusinessSectorName,
                  }))}
                  placeholder={t("BusinessSector")}
                />
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-6 col-12">
              <Field
                inputFieldId={"updateUserAddress"}
                inputFieldType={"text"}
                labelName={t("Address")}
                placeholder={t("Address")}
                inputFieldValue={address}
                setOnChange={(value) => setAddress(value)}
              />
            </div>
            <div className="col-md-6 col-12">
              <Field
                inputFieldId={"updateUserArea"}
                inputFieldType={"text"}
                labelName={t("Area")}
                placeholder={t("Area")}
                inputFieldValue={area}
                setOnChange={(value) => setArea(value)}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-6 col-12">
              <Field
                inputFieldId={"updateUserUserName"}
                inputFieldType={"text"}
                labelName={t("userName")}
                placeholder={t("userName")}
                inputFieldValue={userName}
                setOnChange={(value) => setUserName(value)}
              />
            </div>
            <div className="col-md-6 col-12">

              <Field
                inputFieldId={"updateUserPassword"}
                inputFieldType={"password"}
                labelName={t("password")}
                placeholder={t("password")}
                inputFieldValue={password}
                setOnChange={(value) => setPassword(value)}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-6 col-12">
              <Field
                inputFieldId={"updateUserMobile"}
                inputFieldType={"tel"}
                labelName={t("phoneNumber")}
                placeholder={t("phoneNumber")}
                inputFieldValue={mobile}
                setOnChange={(value) => setMobile(value)}
              />
            </div>
            <div className="col-md-6 col-12">

              <Field
                inputFieldId={"updateUserEmail"}
                inputFieldType={"email"}
                labelName={t("email")}
                placeholder={t("email")}
                inputFieldValue={email}
                setOnChange={(value) => setEmail(value)}
              />
            </div>
          </div>


          <div className="row mb-2">
            <div className="col-md-6 col-12">
              <Field
                inputFieldId={"updateUserBirthday"}
                inputFieldType={"date"}
                labelName={t("Birthday")}
                inputFieldValue={birthday}
                setOnChange={(value) => setBirthday(value)}
              />
            </div>
            <div className="col-md-6 col-12">
              <div>
                <label className={`listLabelStyle ${i18next.language}`} htmlFor="government">{t("Government")}</label>
                <Select
                  className="form-select"
                  value={selectedGovernment}
                  onChange={setSelectedGovernment}
                  styles={customStyles}
                  options={governments.map((g) => ({
                    value: g.name,
                    label: g.name,
                  }))}
                  placeholder={t("Government")}
                />
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-6 col-12">

              <Field
                inputFieldId={"updateUserContactName"}
                inputFieldType={"text"}
                labelName={t("contactPerson")}
                placeholder={t("contactPerson")}
                inputFieldValue={contactName}
                setOnChange={(value) => setContactName(value)}
              />
            </div>
            <div className="col-md-6 col-12">
              <div>
                <label className={`listLabelStyle ${i18next.language}`} htmlFor="religion">{t("Religion")}</label>
                <Select
                  className="form-select"
                  value={selectedReligions}
                  onChange={setSelectedReligions}
                  styles={customStyles}
                  options={religions.map((r) => ({
                    value: r.name,
                    label: r.name,
                  }))}
                  placeholder={t("Religion")}
                />

              </div>
            </div>
          </div>

        </div>}
        popUpFooter={popUpFooter}
        showPopUp={createUserPopUp}
        closePopUp={handleClose}
      />

    </>
  );
}
