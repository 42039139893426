import React, { useState, useMemo, useCallback, useContext } from "react";
import Table from "../../component/newTable";
import { toast } from "react-toastify";
import { GetPriceList } from "../../API";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import Header from "../../component/header";
import AddPriceListPopUp from "../../component/addPriceListPopUp";
import DeletePriceListPopUp from "../../component/deletePriceListPopUp";
import "./style.css";

export default function PriceList() {
  const [data, setData] = useState([]);
  const { role, setAddPriceListPopUp, setDeletePriceListPopUp, reload } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleSideBar] = useState(false);
  const [priceListID, setPriceListID] = useState(null);
  const [priceListName, setPriceListName] = useState(null);
  const { t } = useTranslation();
  const showEdit = (id) => {
    setPriceListID(id);
    setAddPriceListPopUp(true);
  };
  const showDelete = (id, name) => {
    setPriceListID(id);
    setPriceListName(name);
    setDeletePriceListPopUp(true);
  };
  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "ID",
      },
      {
        Header: t("name"),
        accessor: "PriceListName",
      },
      {
        Header: t("priceValue"),
        accessor: "PriceListValue",
      },
      {
        Header: " ",
        accessor: "",
        Cell: ({ row: { original } }) => {
          return (
            <div className="tableActionsCol">
              <i
                className="fas fa-pen "
                onClick={() => showEdit(original.ID)}
              ></i>
              <i className="fas fa-trash" onClick={() => showDelete(original.ID, original.PriceListName)}></i>
            </div>
          );
        },
      },
    ],
    [t, role]
  );
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      setData([]);
      GetPriceList({
      })
        .then((response) => {
          setIsLoading(false);
          setData(response.data.table1);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(`${err}`);
        });
    },
    [role, reload]
  );

  const handleShow = () => {
    setAddPriceListPopUp(true);
    setPriceListID(null);
  };


  return (
    <div className="sendersContainer">
      <Header
        title={t("PriceList")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div className="tableMainTitles">
        <div className="senderTitleBtns">
          <button className="addBusinessSectorBtn" onClick={handleShow}>
            + {t("addPriceList")}
          </button>
        </div>
      </div>
      <div className="tableDiv">
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={isLoading}
          TotalRows={10}
          tableHeader={t("PriceList")}
          noPagination={1}
        />
        <AddPriceListPopUp id={priceListID} />
        <DeletePriceListPopUp id={priceListID} name={priceListName} />
      </div>
    </div>
  );
}
