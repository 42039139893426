import React, { useState, useEffect, useRef, useContext } from "react";
import {
  GetSenders,
  GetUserGroups,
  TestMessage,
  GetClients,
  GetClientsSenders,
  UploadFile,
  UploadPhoneNumbersList
} from "../../API";
import { toast } from "react-toastify";
import { Context } from "../../context";
import TextArea from "../../component/textArea";
import { useTranslation } from "react-i18next";
import PopUp from "../../component/popUp";
import Select from "react-select";
import { NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import moment from "moment";
import Header from "../../component/header";
import Tooltip from "@material-ui/core/Tooltip";
import * as XLSX from 'xlsx';

export default function BulkSMSForm({ sampleURL, smsType }) {
  const { role } = useContext(Context);
  const [manualInput, setManualInput] = useState(true); // Default selection is manual input
  const [fileName, setFileName] = useState("");
  const [textMsg, setTextMsg] = useState("");
  const [phoneNumbersList, setPhoneNumbersList] = useState("");
  const [msgLength, setMsgLength] = useState(0);
  const [errorNumbers, setErrorNumbers] = useState(0);
  const [totalNumbers, setTotalNumbers] = useState(0);
  const [correctNumber, setCorrectNumber] = useState(0);
  const [wrongNumbersList, setWrongNumbersList] = useState([0]);
  const [notActiveNumbersList, setNotActiveNumbersList] = useState([0]);
  const [notActiveNumbers, setNotActiveNumbers] = useState(0);
  const mobileNumberRegex = /^01[0125][0-9]{8}$/;
  const [duplicateNumbers, setduplicateNumbers] = useState(0);
  const [senderList, setSenderList] = useState([]);
  const [selectedSender, setSelectedSender] = useState("-1");
  const [clientsList, setClientsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(-1);
  const [groupList, setGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("-1");
  const [toggleSideBar] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isLoadingConfirm, setIsloadingConfirm] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [campaignName, setCamaginName] = useState(null);
  const [scheduleDate, setScheduleDate] = useState(null);
  const [sendDate, setSendDate] = useState(null);
  const { toggleCredit, reload, setReload } = useContext(Context);
  const [smsCount, setSmsCount] = useState(0);
  const [phoneNumbersCount, setPhoneNumbersCount] = useState(0);
  const fileRef = useRef();
  const [phone, setPhone] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [confirmationPopUp, setConfirmationPopUp] = useState(false);
  const handleCloseConfirmation = () => setConfirmationPopUp(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  let dataPrams;
  const popUpTitle = <div>{t("testPhoneNumberTitle")}</div>;
  const popUpFooter = (
    <button className="sendTesttMsgBtn" onClick={() => testBtn()}>
      {t("testMsgBtn")}
    </button>
  );

  const exportHandler = () => {
    const workbook = XLSX.utils.book_new();
    const sheetData = [['Wrong Numbers']].concat(wrongNumbersList.map(number => [number]));
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Wrong Numbers');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${campaignName}_wrong_numbers.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const exportNotActiveHandler = () => {
    const workbook = XLSX.utils.book_new();
    const sheetData = [['Not Active Numbers']].concat(notActiveNumbersList.map(number => [number]));
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Not Active Numbers');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${campaignName}_not_active_numbers.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };



  const confirmationPopUpTitle = (
    <div className="confirmationPopUpTitle">{t("uploadConfirmation")}</div>
  );
  const confirmationPopUpFooter = (
    <>
      {correctNumber != 0 && (
        <button
          className="sendTesttMsgBtn"
          disabled={isLoadingConfirm}
          onClick={() => upload(0)}
        >
          {isLoadingConfirm && (
            <span className="spinner-border" role="status"></span>
          )}
          {t("confirm")}
        </button>
      )}


      <button className="cancelBtn" onClick={() => cancleUpload()}>
        {t("cancel")}
      </button>
    </>
  );
  const cancleUpload = () => {
    setConfirmationPopUp(false);
    toggleCredit((c) => !c);
    setReload(!reload);
    setIsloading(false);
    setFileName("");
    fileRef.current.value = null;
  };

  const handleChange = () => {
    if (isSchedule) {
      setScheduleDate(null);
    } else {
      setScheduleDate(new Date());
    }

    setIsSchedule(!isSchedule);
  };
  const handleOnChangePhoneNumbersListArea = (e) => {
    const inputValue = e.target.value;
    const regex = /^[0-9\n]*$/;

    // if (!regex.test(inputValue)) {
    //   return;
    // }

    const lines = inputValue.split('\n');

    const formattedLines = lines.map(line => {
      let formattedLine = '';
      for (let i = 0; i < line.length; i++) {
        formattedLine += line[i];
        if ((i + 1) % 12 === 0 && i !== line.length - 1) {
          formattedLine += '\n';
        }
      }
      return formattedLine;
    });

    const formattedInputValue = formattedLines.join('\n');

    setPhoneNumbersList(formattedInputValue);

    if (formattedInputValue.trim() === '') {
      setPhoneNumbersCount(0);
      return;
    }

    const nonEmptyLines = formattedInputValue.split('\n').filter(line => line.trim() !== '');

    const totalLines = nonEmptyLines.length;

    setPhoneNumbersCount(totalLines);
  };


  const handleSenderChange = (selectedOption) => {
    setSelectedSender(selectedOption);

  };



  useEffect(() => {
    if (
      localStorage.getItem("admin") !== "1" ||
      localStorage.getItem("admin") !== "2"
    )
      GetSenders().then((response) => {
        setSenderList(response.data.table1);
      });

    GetUserGroups().then((response) => {
      setGroupList(response.data.table1);
    });

    GetClients().then((response) => {
      setClientsList(response.data.table1);
    });
  }, [role, t]);

  useEffect(() => {
    if (smsType != "1") {
      setManualInput(false);
    }
  }, [t, smsType]);

  useEffect(() => {
    if (
      role &&
      (localStorage.getItem("admin") === "1" ||
        localStorage.getItem("admin") === "2")
    ) {
      GetClientsSenders({
        "@ClientID": selectedClient.value || -1,
      }).then((response) => {
        setSenderList(response.data.table1);
      });
    }
  }, [t, selectedClient, role]);

  const sendTestMsg = () => {
    let modifiedPhone = phone;
    const phoneRegex = /^01[0125][0-9]{8}$/;
    const phoneRegexWith2 = /^201[0125][0-9]{8}$/;

    if (phone === "" || (!phoneRegex.test(phone) && !phoneRegexWith2.test(phone))) {
      toast.error(`${t("testPhoneNumberTitle")}`);
      return;
    }

    if (phoneRegex.test(phone)) {
      modifiedPhone = "2" + phone;
    }

    TestMessage({
      "@message": textMsg,
      "@phoneNumber": modifiedPhone,
      "@sender": selectedSender.value,
      "@clientID": selectedClient.value || localStorage.getItem("userId"),
    }).then((response) => {
      toast.success(`${t("successfullySent")}`);
      setPhone("");
      setReload(!reload);
    });
  };

  const resetForm = () => {
    setSelectedSender("-1");
    setCamaginName("");
    setSelectedClient("-1");
    setManualInput("");
    setTextMsg("");
    setFileName("");
    setSmsCount(0);
    setMsgLength(0);
    setSelectedGroup("-1");

  }

  const testBtn = () => {
    handleClose();
    sendTestMsg();
  };

  // const handleOnChangeArea = (e) => {
  //   debugger;
  //   const updatedText = e.target.value.replace(/\n/g, '\r\n');
  //   setTextMsg(updatedText);

  //   if (updatedText === '') {
  //     setSmsCount(0);
  //     setMsgLength(0);
  //     return;
  //   }

  //   let unicodeFlag = 0;
  //   let extraChars = 0;
  //   let msgCount = 0;

  //   for (let i = 0; (!unicodeFlag && (i < updatedText.length)); i++) {
  //     const charCode = updatedText.charCodeAt(i);
  //     switch (true) {
  //       case (charCode >= 0x20 && charCode <= 0x7E):
  //         break;
  //       case (charCode == 0x394 || charCode == 0x3A6 || charCode == 0x393 || charCode == 0x39B || charCode == 0x3A9 || charCode == 0x3A0 || charCode == 0x3A8 || charCode == 0x3A3 || charCode == 0x398 || charCode == 0x39E || charCode == 0x3A6 || charCode == 0x39B || charCode == 0x3A9 || charCode == 0x3A0 || charCode == 0x3A8 || charCode == 0x3A3 || charCode == 0x398 || charCode == 0x39E): // Greek characters
  //         break;
  //       case (charCode >= 0x20AC):
  //         extraChars++;
  //         break;
  //       case (charCode == 13 || charCode == 10):
  //         break;
  //       default:
  //         unicodeFlag = 1;
  //     }
  //   }


  //   if (unicodeFlag) {
  //     msgCount = updatedText.length;
  //     if (msgCount <= 70) {
  //       msgCount = 1;
  //     } else {
  //       msgCount += (63 - 1);
  //       msgCount -= (msgCount % 63);
  //       msgCount /= 63;
  //     }
  //   } else {
  //     msgCount = updatedText.length + extraChars;
  //     if (msgCount <= 160) {
  //       msgCount = 1;
  //     } else {
  //       msgCount += (153 - 1);
  //       msgCount -= (msgCount % 153);
  //       msgCount /= 153;
  //     }
  //   }

  //   setMsgLength(updatedText.length);
  //   setSmsCount(msgCount);
  // };


  // const handleOnChangeArea = (e) => {
  //   const updatedText = e.target.value.replace(/\n/g, '\r\n');
  //   setTextMsg(updatedText);

  //   if (updatedText === '') {
  //     setSmsCount(0);
  //     setMsgLength(0);
  //     return;
  //   }

  //   let unicodeFlag = false;
  //   let englishCount = 0;
  //   let arabicCount = 0;

  //   for (let i = 0; i < updatedText.length; i++) {
  //     const charCode = updatedText.charCodeAt(i);
  //     if (charCode >= 0x20 && charCode <= 0x7E) {
  //       englishCount++; // English characters
  //     } else if (charCode >= 0x0600 && charCode <= 0x06FF) {
  //       arabicCount++; // Arabic characters
  //     } else {
  //       unicodeFlag = true; // Contains unsupported characters
  //     }
  //   }

  //   let msgCount = 0;

  //   // Count SMS segments for English
  //   if (englishCount > 0) {
  //     msgCount = Math.ceil(englishCount / 160);
  //   }

  //   // Count SMS segments for Arabic
  //   if (arabicCount > 0) {
  //     if (arabicCount <= 70) {
  //       msgCount = 1;
  //     } else if (arabicCount <= 134) {
  //       msgCount = 2;
  //     } else if (arabicCount <= 201) {
  //       msgCount = 3;
  //     } else if (arabicCount <= 268) {
  //       msgCount = 4;
  //     } else {
  //       msgCount = Math.ceil((arabicCount - 268) / 65) + 4; // Add segments for remaining characters
  //     }
  //   }

  //   // If both English and Arabic characters are present, choose the higher count
  //   if (englishCount > 0 && arabicCount > 0) {
  //     msgCount = Math.max(
  //       Math.ceil(englishCount / 160),
  //       msgCount
  //     );
  //   }

  //   setMsgLength(updatedText.length);
  //   setSmsCount(msgCount);
  // };

  // const handleOnChangeArea = (e) => {
  //   const updatedText = e.target.value.replace(/\n/g, '\r\n');
  //   setTextMsg(updatedText);

  //   if (updatedText === '') {
  //     setSmsCount(0);
  //     setMsgLength(0);
  //     return;
  //   }

  //   let unicodeFlag = false;
  //   let arabicCount = 0;
  //   let msgCount = 0;

  //   for (let i = 0; i < updatedText.length; i++) {
  //     const charCode = updatedText.charCodeAt(i);
  //     if (charCode >= 0x0600 && charCode <= 0x06FF) {
  //       arabicCount++;
  //     } else if (charCode < 0x20 || (charCode > 0x7E && charCode < 0xA0)) {
  //       unicodeFlag = true;
  //       break;
  //     }
  //   }

  //   if (arabicCount > 0) {
  //     if (updatedText.length <= 70) {
  //       msgCount = 1;
  //     } else if (updatedText.length <= 134) {
  //       msgCount = 2;
  //     } else if (updatedText.length <= 201) {
  //       msgCount = 3;
  //     } else if (updatedText.length <= 268) {
  //       msgCount = 4;
  //     } else {
  //       msgCount = Math.ceil((updatedText.length - 268) / 65) + 4;
  //     }
  //   } else {
  //     msgCount = updatedText.length;
  //     if (msgCount <= 160) {
  //       msgCount = 1;
  //     } else {
  //       msgCount += (153 - 1);
  //       msgCount -= (msgCount % 153);
  //       msgCount /= 153;
  //     }
  //   }

  //   setMsgLength(updatedText.length);
  //   setSmsCount(msgCount);
  // };

  const handleOnChangeArea = (e) => {
    const updatedText = e.target.value; 

    setTextMsg(updatedText);

    if (updatedText === '') {
        setSmsCount(0);
        setMsgLength(0);
        return;
    }

    let unicodeFlag = false;
    let arabicCount = 0;
    let msgCount = 0;

 
    const normalizedText = updatedText.replace(/\n/g, '  '); 


    for (let i = 0; i < normalizedText.length; i++) {
        const charCode = normalizedText.charCodeAt(i);
     
        if (charCode >= 0x0600 && charCode <= 0x06FF) {
            arabicCount++;
        } 
     
        else if (charCode < 0x20 || (charCode > 0x7E && charCode < 0xA0)) {
            unicodeFlag = true;
            break;
        }
    }


    if (arabicCount > 0 || unicodeFlag) {

        if (normalizedText.length <= 70) {
            msgCount = 1;
        } else if (normalizedText.length <= 134) {
            msgCount = 2;
        } else if (normalizedText.length <= 201) {
            msgCount = 3;
        } else if (normalizedText.length <= 268) {
            msgCount = 4;
        } else {
            msgCount = Math.ceil((normalizedText.length - 268) / 65) + 4; 
        }
    } else {

        msgCount = normalizedText.length;
        if (msgCount <= 160) {
            msgCount = 1;
        } else {
            msgCount += (153 - 1);
            msgCount -= (msgCount % 153);
            msgCount /= 153;
        }
    }

    setMsgLength(normalizedText.length);
    setSmsCount(msgCount);
};







  const handleFile = () => {
    let file = fileRef.current.files[0];

    if (file) setFileName(file.name);
  };

  const upload = (check) => {
    if (campaignName == "" || campaignName == null) {
      toast.error("Enter Campaign Name");
      return;
    }

    let file = fileRef.current?.files[0];

    if (!manualInput) {
      if ((!file && selectedGroup == "-1") || selectedSender === "-1") {
        toast.error(`${t("failedUpload")}`);
        return;
      }
    }
    if (selectedSender === "-1") {
      toast.error(`${t("failedUpload")}`);
      return;
    }



    if (smsType == "1" && textMsg.trim() === "") {
      toast.error(`Enter Your Message`);
      return;
    }

    let formData = new FormData();


    if (isSchedule) {
      setSendDate(moment(scheduleDate).toDate());
    }
    else setSendDate(null);

    if (smsType == "1") {
      dataPrams = {
        "@adminID": localStorage.getItem("userId"),
        "@sender": selectedSender.value,
        "@clientID": selectedClient.value || localStorage.getItem("userId"),
        "@message": textMsg,
        "@sendDate": sendDate,
        "@campaignName": campaignName,
        "@smsType": 2,
      };
      if (selectedGroup != "-1") {
        dataPrams["@groupID"] = selectedGroup.value;
        dataPrams['@fileName'] = "no file uploaded";
      }
    } else {
      dataPrams = {
        "@adminID": localStorage.getItem("userId"),
        "@sender": selectedSender.value,
        "@clientID": selectedClient.value || localStorage.getItem("userId"),
        "@sendDate": sendDate,
        "@campaignName": campaignName,
        "@smsType": 1
      };
    }


    const data = {
      ConnectionStringName: "AdvansysBulk",
      StoredProcedureName: "Dashboard.InsertBulkSMS",
      SpParams: dataPrams,
    };

    if (!manualInput) {
      formData.append("file", file);
      formData.append("data", JSON.stringify(data));
      formData.append("check", check);
    }
    else {
      const phoneNumbers = phoneNumbersList.split('\n').map(phone => phone.trim()).filter(phone => phone !== '');

      const formattedPhoneNumbers = phoneNumbers.map(phone => {
        if (/^1[0125][0-9]{8}$/.test(phone)) {
          return `20${phone}`;
        }
        if (/^01[0125][0-9]{8}$/.test(phone)) {
          return `2${phone}`;
        }
        return phone;
      });
      formData.append("data", JSON.stringify(data));
      formData.append("phoneNumbersList", formattedPhoneNumbers.join('\n'));
      formData.append("check", check);
    }

    if (check === 1) setIsloading(true);
    else setIsloadingConfirm(true);

    if (!manualInput) {

      UploadFile(formData)
        .then((res) => {
          if (res.status === 200) {
            if (check === 1) {
              setIsloading(false);
              setTotalNumbers(res.data.totalCount);
              setErrorNumbers(res.data.errorCount);
              setNotActiveNumbers(res.data.notActiveCount);
              setCorrectNumber(res.data.correctCount);
              setduplicateNumbers(res.data.duplicateCount);
              setWrongNumbersList(res.data.wrongNumbers);
              setNotActiveNumbersList(res.data.notActiveNumbers);
              setConfirmationPopUp(true);
            } else if (res.data.Table[0].success == 0) {
              toast.error(`you don't have enough sms balance`);
              fileRef.current.value = null;
              setIsloading(false);
              setIsloadingConfirm(false);
              setConfirmationPopUp(false);
              setFileName("");

              setMsgLength(0);
            } else {
              toast.success(`${t("successfullyUploaded")}`);
              fileRef.current.value = null;
              toggleCredit((c) => !c);
              setReload(!reload);
              setIsloading(false);
              setConfirmationPopUp(false);
              setIsloadingConfirm(false);
              setFileName("");
              setSmsCount(0);
              setMsgLength(0);
              resetForm();
            }
          }
        })
        .catch((error) => {
          setIsloading(false);
          setIsloadingConfirm(false);
        });
    }
    else {

      UploadPhoneNumbersList(formData)
        .then((res) => {
          if (res.status === 200) {
            setConfirmationPopUp(false);
            if (check === 1) {
              setIsloading(false);
              setTotalNumbers(res.data.totalCount);
              setErrorNumbers(res.data.errorCount);
              setNotActiveNumbers(res.data.notActiveCount);
              setCorrectNumber(res.data.correctCount);
              setduplicateNumbers(res.data.duplicateCount);
              setWrongNumbersList(res.data.wrongNumbers);
              setNotActiveNumbersList(res.data.notActiveNumbers);
              setConfirmationPopUp(true);
            } else if (res.data.Table[0].success == 0) {
              toast.error(`you don't have enough sms balance`);
              fileRef.current.value = null;
              setIsloading(false);
              setIsloadingConfirm(false);
              setConfirmationPopUp(false);
              setFileName("");
              setMsgLength(0);
            } else {
              resetForm();
              toast.success(`${t("successfullyUploaded")}`);
              fileRef.current.value = null;
              toggleCredit((c) => !c);
              setReload(!reload);
              setIsloading(false);
              setConfirmationPopUp(false);
              setIsloadingConfirm(false);
              setFileName("");
              setSmsCount(0);
              setMsgLength(0);

            }
          }
        })
        .catch((error) => {
          setIsloading(false);
          setIsloadingConfirm(false);
          setConfirmationPopUp(false);
        });
    }

    moment(sendDate);
  };


  return (
    <div className="sendBulkSMS">
      <Header
        title={smsType == "1" ? t("bulkMessage") : t("oneToOneMessage")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div className="tableDiv">
        <div className="sendSmsInfoSectionTitle">{t("mainInfo")}</div>
        <div className="mainInfoSection1">
          <div style={{ width: "100%" }}>
            {(localStorage.getItem("admin") === "1" ||
              localStorage.getItem("admin") === "2") && (
                <Select
                  id="clientDL"
                  className="form-select"
                  value={selectedClient}
                  onChange={setSelectedClient}
                  options={clientsList.map((c) => ({
                    value: c.ID,
                    label: c.Name,
                  }))}
                  placeholder={t("selectClient")}
                />
              )}
            <Select
              id="senderDL"
              className="form-select"
              value={selectedSender}
              onChange={handleSenderChange}
              options={senderList.map((s) => ({
                value: s.Name,
                label: s.Name
              }))}
              placeholder={t("selectSender")}
            />
          </div>
          <div className="isScheduleCheckBox">
            <div className="d-flex mt-3 mb-1">
              <div>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isSchedule}
                    onChange={() => handleChange()}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <label id="toggleBtnLabel" htmlFor="isScheduletoggleBtn">
                {t("scaduleMessage")}
              </label>
            </div>
            {isSchedule && (
              <div className="scheduleDate">
                <DatePicker
                  selected={scheduleDate}
                  startDate={scheduleDate}
                  onChange={(date) => setScheduleDate(date)}
                  className="scheduleDateStyle"
                  minDate={new Date()}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                />
              </div>
            )}
          </div>


        </div>



        <div className="mainInfoSection1">

          <div className="defaultWidthSize">
            {smsType == "1" && (
              <>
                <TextArea
                  classStyle={"messageTestArea"}
                  inputFieldValue={textMsg}
                  setOnChange={(e) => handleOnChangeArea(e)}
                  placeholder={t("textAreaMessage")}
                />
                <div className="msgLengthDiv">
                  {msgLength} {t("character")} - {smsCount} {t("sms")}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="campaignNameDiv">
          <label className="formLabelStyle">{t("campaignName")}</label>
          <input
            type="text"
            className="form-control campaignName"
            value={campaignName}
            onChange={(e) => setCamaginName(e.target.value)}
            placeholder={t("campaignName")}
          />
        </div>

        <div className="receiversSectionTitle">
          <div className="sendSmsInfoSectionTitle">{t("receiversInfo")}</div>
          {!manualInput && (
            <button
              className="downloadBtn"
              disabled={isLoading}
              onClick={() => window.open(sampleURL, "_self")}
            >
              <i className="fas fa-cloud-download-alt downloadBtnIcon"></i>
              {t("downloadSample")}
            </button>)}
        </div>


        <div className="defaultWidthSize">
          {(smsType == "1") && (<><div className="formLabelStyle mb-1">{t("receiversPhoneNumbers")}</div>
            <div className="radioBtnsUploadNumberTypeContainer">
              <div>
                <input
                  type="radio"
                  id="addManually"
                  value="manual"
                  checked={manualInput}
                  onChange={() => { setManualInput(true); setSelectedGroup("-1") }}
                />
                <label htmlFor="addManually">{t("addManually")}</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="uploadFile"
                  value="file"
                  checked={!manualInput}
                  onChange={() => { setManualInput(false); setSelectedGroup("-1") }}
                />
                <label htmlFor="uploadFile">{t("uploadExcelFileRadioBtn")}</label>
              </div>
            </div></>)}
          <br />
          {manualInput && (
            <>
              <label className="formLabelStyle">
                {t("phoneNumbersList")}
                <Tooltip title={t("phoneNumbersGuide")} placement="top">
                  <i className="fas fa-info-circle ml-1 mr-1"></i>
                </Tooltip>
              </label>
              <TextArea
                classStyle={"messageTestArea"}
                inputFieldValue={phoneNumbersList}
                setOnChange={(e) => handleOnChangePhoneNumbersListArea(e)}
                placeholder={t("phoneNumbersList")}
                inputFieldType={"number"}
              />
              <div className="msgLengthDiv">
                {phoneNumbersCount}
                {t("phoneNumberCounter")}
              </div>
            </>
          )}
          {!manualInput && (
            <div className="form">
              {(smsType == "1") && (
                <>
                  <Select
                    id="clientDL"
                    className="form-select"
                    value={selectedGroup}
                    onChange={setSelectedGroup}
                    options={groupList.map((g) => ({
                      value: g.id,
                      label: g.groupName,
                    }))}
                    placeholder={t("selectGroup")}
                    isDisabled={fileName != ""}
                  />
                  <div className="d-flex justify-content-center formLabelStyle" style={{ width: "90%" }}>{t("or")}</div>

                </>

              )}
              <label className="formLabelStyle">
                {t("uploadExcelFile")}
                <Tooltip title={t("sampleFileGuide")} placement="top">
                  <i className="fas fa-info-circle ml-1 mr-1"></i>
                </Tooltip>
              </label>
              <div className="uploadPhoneNumbersFile">
                <input
                  id="uploadedFileName"
                  type="text"
                  value={fileName || ""}
                  readOnly
                  disabled={selectedGroup != "-1"}
                />
                <button
                  className="uploadInputFileBtn"
                  onClick={() => fileRef.current.click()}
                  disabled={selectedGroup != "-1"}
                >
                  <i className="fas fa-cloud-upload-alt downloadBtnIcon"></i>
                  {t("uploadFileBtn")}
                </button>
                <input
                  ref={fileRef}
                  id="uploadedFile"
                  type="file"
                  onChange={handleFile}
                  accept=".csv, .xlsx"
                  style={{ display: "none" }}
                />
              </div>
            </div>
          )}
        </div>

        <div id="uploadBtnDiv" className="mt-2">
          <button
            id="uploadBtn"
            className="uploadBtn"
            onClick={() => upload(1)}
            disabled={isLoading}
          >
            {isLoading && <span className="spinner-border" role="status"></span>}
            {t("send")}
          </button>

          {textMsg && smsType == "1" && selectedSender != "-1" && (
            <button
              id="testBtn"
              className="testBtn"
              disabled={isLoading}
              onClick={handleShow}
            >
              {t("testMsgBtn")}
            </button>
          )}

        </div>

      </div>
      <PopUp
        popUpTitle={popUpTitle}
        children={
          <div className={`form-group ${i18n.language}`}>
            <input
              id="phoneNumber"
              type="number"
              className="form-control"
              placeholder={t("testPhoneNumber")}
              value={phone}
              onChange={(event) =>
                setPhone(
                  event.target.value
                )
              }
            />
          </div>
        }
        popUpFooter={popUpFooter}
        showPopUp={show}
        closePopUp={handleClose}
      />

      <PopUp
        popUpTitle={confirmationPopUpTitle}
        children={
          <div className={`form-group ${i18n.language}`}>
            <div className="confirmationDiv">
              <div className="totalNumbers">
                <div>
                  {" "}
                  <img src="images/call.png" alt="Correct Numbers" />
                </div>
                {t("totalNumbers")}: {totalNumbers}
              </div>
            </div>
            <div className="confirmationDiv">
              <div className="totalNumbers">
                <div>
                  {" "}
                  <img src="images/call.png" alt="Correct Numbers" />
                </div>
                {t("correctNumbers")}: {correctNumber}
              </div>
            </div>
            <div className="confirmationDiv">
              <div className="wrongNumbersError">
                <div className="wrongNumbersErrorLabel">

                  <img src="images/cross.png" alt="Correct Numbers" />

                  {t("wrongNumbers")}: {errorNumbers}
                </div>

                {(wrongNumbersList && wrongNumbersList.length > 0) && (<button
                  className="downloadWrongNumbersBtn"
                  onClick={() => exportHandler()}
                >
                  {t("Export")}

                </button>)}
              </div>
            </div>
            {(notActiveNumbersList && notActiveNumbersList.length > 0) && (<div className="confirmationDiv">
              <div className="wrongNumbersError">
                <div className="wrongNumbersErrorLabel">

                  <img src="images/cross.png" alt="Correct Numbers" />

                  {t("notActiveNumbers")}: {notActiveNumbers}
                </div>

                {(notActiveNumbersList && notActiveNumbersList.length > 0) && (<button
                  className="downloadWrongNumbersBtn"
                  onClick={() => exportNotActiveHandler()}
                >
                  {t("Export")}

                </button>)}
              </div>
            </div>)}

            <div className="confirmationDiv">
              <div className="dublicateNumbersError">
                <img src="images/cross.png" alt="duplicate Numbers" />
                {t("duplicateNumbers")}: {duplicateNumbers}
              </div>
            </div>

          </div>
        }
        popUpFooter={confirmationPopUpFooter}
        showPopUp={confirmationPopUp}
        closePopUp={handleCloseConfirmation}
      />
    </div>
  );
}
