import React, { useState, useContext, useEffect } from "react";
import PopUp from "../../component/popUp";
import { toast } from "react-toastify";
import { AddPriceList, GetPriceList } from "../../API";
import Field from "../../component/field";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import "./style.css";


export default function AddBusinessSectorPopUp({ id }) {
  const { addPriceListPopUp, setAddPriceListPopUp, reload, setReload } = useContext(Context);
  const [addPriceListLoading, setaddPriceListLoading] = useState(false);
  const [priceListName, setPriceListName] = useState();
  const [priceListValue, setPriceListValue] = useState();
  const handleClose = () => { setAddPriceListPopUp(false); setPriceListName(""); }

  const { t } = useTranslation();

  useEffect(() => {
    if (!id) {
      setPriceListName("");
      setPriceListValue("");
      return;
    }

    GetPriceList({
      "@ID": id
    })
      .then((response) => {
        setPriceListName(response.data.table1[0].PriceListName);
        setPriceListValue(response.data.table1[0].PriceListValue);
        addPriceListLoading(false);
      })
      .catch((error) => {
        addPriceListLoading(false);
      });
  }, [id, addPriceListPopUp])


  const save = () => {
    if (!priceListName || priceListName.trim() === "" || !priceListValue) {
      toast.error(t("fillAllFields"));
      return;
    }
    setaddPriceListLoading(true);
    AddPriceList({
      "@ID": id,
      "@Name": priceListName,
      "@Value": priceListValue
    })
      .then((response) => {
        setaddPriceListLoading(false);
        toast.success(t("PriceListAddedSuccessfully"));
        setReload(!reload);
        setPriceListName("");
        setAddPriceListPopUp(false);
      })
      .catch((err) => {
        setaddPriceListLoading(false);
        toast.error(`${err}`);
      });
  }
  const popUpTitle = `${t("addPriceList")}`;

  const popUpFooter = (
    <>
      <button
        className="footerPopUpBtns"
        disabled={addPriceListLoading}
        onClick={() => save()}
      >
        {addPriceListLoading && (
          <span className="spinner-border text-dark" role="status"></span>
        )}
        {id == null ? t("addBtn") : t("Save")}

      </button>
      <button className="closeBtn" onClick={handleClose}>
        {t("closeBtn")}
      </button>
    </>
  );


  return (

    <PopUp
      popUpTitle={popUpTitle}
      popUpFooter={popUpFooter}
      showPopUp={addPriceListPopUp}
      closePopUp={handleClose}
    >
      <div className="row mb-2">
        <div className="col-md-6 col-12">

          <Field
            inputFieldId={"AddPriceList"}
            inputFieldType={"text"}
            labelName={t("PriceListName")}
            placeholder={t("PriceListName")}
            inputFieldValue={priceListName}
            setOnChange={(value) => setPriceListName(value)}
            isDisabled={addPriceListLoading}
          />
        </div>
        <div className="col-md-6 col-12">

          <Field
            inputFieldId={"AddPriceList"}
            inputFieldType={"number"}
            labelName={t("PriceListValue")}
            placeholder={t("PriceListValue")}
            inputFieldValue={priceListValue}
            setOnChange={(value) => setPriceListValue(value)}
            isDisabled={addPriceListLoading}
          />
        </div>
      </div>
    </PopUp>
  );
}
