import { useState, useEffect, useContext } from "react";
import React from "react";
import { Context } from "../../context";
import LineChart from "../../component/statisticsChart";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../component/header";
import DateRangePicker from "../../component/dateRangePicker";
import {
  GetAdminChartData,
  GetChartData,
  GetStatisticsAdmin,
  GetUserStatistics,
  GetAllSenders,
  GetAllCampaigns,
  GetCreditV2,
  GetScheduleSMSReport,
  GetOperatorsStatistics,
} from "../../API";
import { toast } from "react-toastify";
import { Pie } from "react-chartjs-2";
import ReadOnlyCalendar from "../../component/readOnlyCalendar";
import AddSenderPopUp from "../../component/addSenderPopUp";
import "./style.css";

function Dashboard() {
  const { role, startDate, endDate, addSenderPopUp, setAddSenderPopUp } = useContext(Context);
  const [smsCount, setSmsCount] = useState();
  const [smsLength, setSmsLength] = useState();
  const [sendersCount, setSendersCount] = useState();
  const [campignCount, setCampignCount] = useState();
  const [credit, setCredit] = useState();
  const { reloadCredit } = useContext(Context);
  const [dataAllCampign, setDataAllCampign] = useState([]);
  const [events, setEvents] = useState([]);
  const [dataScheduledSms, setDataScheduledSms] = useState([]);
  const [dashboardSenderList, setDashboardSenderList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPieChart, setIsLoadingPieChart] = useState(false);
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
      },
    ],
  });

  const [options, setOptions] = useState({
    aspectRatio: 1,
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          usePointStyle: true,
          pointStyle: "circle",
          paddingTop: 10,
          generateLabels: (chart) => {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.labels.map((label, index) => {
                const value = data.datasets[0].data[index];
                const countValue = data.datasets[0].counts[index];
                const formattedValue = `${value}%`;
                return {
                  text: `${label} (${countValue}): ${formattedValue}`,
                  fillStyle: data.datasets[0].backgroundColor[index],
                  lineWidth: 0,
                };
              });
            }
            return [];
          },
        },
      },
    },
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    labels: [],
    datasets: [
      {
        label: "SMS Count",
        data: [],
        backgroundColor: ["#F94144"],
        borderColor: "#F94144",
        borderWidth: 1,
      },
    ],
  });
  const [adminData, setAdminData] = useState({
    labels: [],
    datasets: [
      {
        label: "SMS Count",
        data: [],
        backgroundColor: ["#F94144"],
        borderColor: "#F94144",
        borderWidth: 1,
      },
    ],
  });
  const [toggleSideBar] = useState(false);

  useEffect(() => {
    if (!role) return;
    setIsLoading(true);
    setIsLoadingPieChart(true);
    setSmsCount();
    setSmsLength();
    GetAllSenders({})
      .then((response) => {
        setIsLoading(false);
        console.log(response);
        setDashboardSenderList(response.data.table1);
        setSendersCount(response.data.table2[0].Total);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(`${err}`);
      });
    if (
      localStorage.getItem("admin") === "1" ||
      localStorage.getItem("admin") === "2"
    )
      GetStatisticsAdmin({
        "@startDate": new Intl.DateTimeFormat("en").format(startDate),
        "@endDate": new Intl.DateTimeFormat("en").format(endDate),
      })
        .then((response) => {
          if (response.data.table1[0].SMSCountTotal == null) setSmsCount(0);
          else
            setSmsCount(
              response.data.table1[0].SMSCountTotal.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })
            );
          if (response.data.table1[0].SMSLengthTotal == null) setSmsLength(0);
          else
            setSmsLength(
              response.data.table1[0].SMSLengthTotal.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })
            );
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });

    if (
      localStorage.getItem("admin") !== "1" &&
      localStorage.getItem("admin") !== "2"
    )
      GetUserStatistics({
        "@startDate": new Intl.DateTimeFormat("en").format(startDate),
        "@endDate": new Intl.DateTimeFormat("en").format(endDate),
      })
        .then((response) => {
          if (response.data.table1[0].SMSCount == null) setSmsCount(0);
          else
            setSmsCount(
              response.data.table1[0].SMSCount.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })
            );
          if (response.data.table1[0].Length == null) setSmsLength(0);
          else
            setSmsLength(
              response.data.table1[0].Length.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })
            );
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });

    setIsLoadingChart(true);

    if (
      role &&
      localStorage.getItem("admin") !== "1" &&
      localStorage.getItem("admin") !== "2"
    )
      GetChartData({
        "@startDate": new Intl.DateTimeFormat("en").format(startDate),
        "@endDate": new Intl.DateTimeFormat("en").format(endDate),
      })
        .then((response) => {
          setIsLoadingChart(false);
          setUserData((prev) => {
            return {
              ...prev,
              labels: response.data.table1.map((data) => data.Date),
              datasets: [
                {
                  ...prev.datasets[0],
                  data: response.data.table1.map((data) => data.SMSCount),
                },
              ],
            };
          });
        })
        .catch((error) => {
          setIsLoadingChart(false);
        });

    if (
      role &&
      (localStorage.getItem("admin") === "1" ||
        localStorage.getItem("admin") === "2")
    )
      GetAdminChartData({
        "@startDate": new Intl.DateTimeFormat("en").format(startDate),
        "@endDate": new Intl.DateTimeFormat("en").format(endDate),
      })
        .then((response) => {
          setIsLoadingChart(false);
          setAdminData((prev) => {
            return {
              ...prev,
              labels: response.data.table1.map((data) => data.Date),
              datasets: [
                {
                  ...prev.datasets[0],
                  data: response.data.table1.map((data) => data.SMSCount),
                },
              ],
            };
          });
        })
        .catch((error) => {
          setIsLoadingChart(false);
        });

    GetAllCampaigns({
      "@startDate": new Intl.DateTimeFormat("en").format(startDate),
      "@endDate": new Intl.DateTimeFormat("en").format(endDate)
    })
      .then((response) => {
        setDataAllCampign(response.data.table1);
        setCampignCount(response.data.table2[0].Total);
        setIsLoading(false);

      })
      .catch((error) => {
        setIsLoading(false);
      });
    GetScheduleSMSReport({
      "@startDate": new Intl.DateTimeFormat("en").format(startDate),
      "@endDate": new Intl.DateTimeFormat("en").format(endDate),
    })
      .then((response) => {
        setIsLoading(false);
        console.log(response);
        setDataScheduledSms(response.data.table1);
        const campaignDates = response.data.table1.map((campaign) => {
          const date = new Date(campaign.SendDate);
          const formattedDate = new Intl.DateTimeFormat("en", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }).format(date);
          return formattedDate.replace(/\//g, "-"); // Replace / with -
        });
        setEvents(campaignDates);
      })
      .catch((error) => {
        setIsLoading(false);
      });
    GetCreditV2().then((data) => {
      if (data.data.table1[0].Credit == null) setCredit(0);
      else setCredit(data.data.table1[0].Credit);
    });
    // Make sure to replace the placeholder for your API call function and adjust the parameters accordingly.
    GetOperatorsStatistics({
      "@startDate": new Intl.DateTimeFormat("en").format(startDate),
      "@endDate": new Intl.DateTimeFormat("en").format(endDate),
    })
      .then((response) => {
        setData({
          labels: response.data.table1.map(
            (operator) => operator.Operator_Name == 'Mobinil' ? 'Orange' : operator.Operator_Name
          ),
          datasets: [
            {
              data: response.data.table1.map((operator) => operator.percentage),
              counts: response.data.table1.map((operator) => operator.count),
              backgroundColor: ["#080808", "#5C5C5C", "#9C9C9C", "#EFF4FB"], // Example colors
              hoverBackgroundColor: [
                "#080808",
                "#5C5C5C",
                "#9C9C9C",
                "#EFF4FB",
              ], // Example hover colors
            },
          ],
        });

        setIsLoadingPieChart(false);
      })
      .catch((error) => {
        setIsLoadingPieChart(false);
        // Handle errors
      });
  }, [startDate, endDate, t, role, reloadCredit]);


  return (
    <div className="dashboardContainer">
      <Header
        title={t("dashboard")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div className="tableMainTitles">
        <DateRangePicker />
      </div>
      <div className="statisticsNumbersDiv">
        <div className="statisticsCardStyle">
          <span className="statisticsCardIconContainer">
            <img
              className="cardsIcons"
              src="images/smsLogo.png"
              alt="sms icon"
            />
          </span>
          <div className="statisticsCardContent">
            <span className="statisticsCardTitle">{t("count")}</span>
            {isLoading && (
              <span className="spinner-border" role="status"></span>
            )}
            <span className="statisticsCardValue">
              {smsCount} {t("sms")}
            </span>
          </div>
        </div>
        <div className="statisticsCardStyle">
          <span className="statisticsCardIconContainer">
            <img
              className="cardsIcons"
              src="images/smsLength.png"
              alt="sms icon"
            />
          </span>
          <div className="statisticsCardContent">
            <span className="statisticsCardTitle">{t("length")}</span>
            {isLoading && (
              <span className="spinner-border" role="status"></span>
            )}
            <span className="statisticsCardValue">{smsLength}</span>
          </div>
        </div>
        <div className="statisticsCardStyle">
          <span className="statisticsCardIconContainer">
            <img
              className="cardsIcons"
              src="images/totalMoney.png"
              alt="sms icon"
            />
          </span>
          <div className="statisticsCardContent">
            <span className="statisticsCardTitle">{t("TotalBalance")}</span>
            {isLoading && (
              <span className="spinner-border" role="status"></span>
            )}
            <span className="statisticsCardValue">{credit}</span>
          </div>
        </div>
        <div className="statisticsCardStyle">
          <span className="statisticsCardIconContainer">
            <img
              className="cardsIcons"
              src="images/campigns.png"
              alt="sms icon"
            />
          </span>
          <div className="statisticsCardContent">
            <span className="statisticsCardTitle">{t("TotalCampign")}</span>
            {isLoading && (
              <span className="spinner-border" role="status"></span>
            )}
            <span className="statisticsCardValue">{campignCount}</span>
          </div>
        </div>
        <div className="statisticsCardStyle">
          <span className="statisticsCardIconContainer">
            <img
              className="cardsIcons"
              src="images/senders.png"
              alt="sms icon"
            />
          </span>
          <div className="statisticsCardContent">
            <span className="statisticsCardTitle">{t("senderCount")}</span>
            {isLoading && (
              <span className="spinner-border" role="status"></span>
            )}
            <span className="statisticsCardValue">{sendersCount}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 col-sm-12 colsSpaces">
          <div className="chartStyle">
            <div className="dashboardLineChartCard">
              {isLoadingChart && (
                <span className="spinner-border" role="status"></span>
              )}
              <div className="chartSmsCountLabel">
                {smsCount} {t("sms")}
                <span className="tableCardHeaderIcon">
                  <img
                    className="cardsIcons"
                    src="images/chart.png"
                    alt="chart"
                    onClick={() => navigate("/reports")}
                  />
                </span>
              </div>
              <div className="row">
                <div className="col-md-2 col-sm-12">
                  <span className="chartLabel">
                    <div className="redCircle"></div>
                    {t("count")}
                  </span>
                </div>
                <div className="col-md-10 col-sm-12">
                  <div className="lineChartContainer">
                    <LineChart
                      chartData={
                        localStorage.getItem("admin") === "1" ||
                          localStorage.getItem("admin") === "2"
                          ? adminData
                          : userData
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* {localStorage.getItem("admin") !== "1" &&
            localStorage.getItem("admin") !== "2" && (
              <div>
                <div className="statisticsCardValue">
                  <span>{t("count")}</span>
                </div>
                {isLoadingChart && (
                  <span className="spinner-border" role="status"></span>
                )}
                <div
                  className="lineChartContainer"
                  style={{ width: "100%", height: "100%" }}
                >
                  <LineChart chartData={userData} />
                </div>
              </div>
            )} */}
        </div>
        <div className="col-md-4 col-sm-12 colsSpaces">
          <div className="dashboardRightSideCard">
            <span className="mb-2">{t("AllOperators")}</span>
            {isLoadingPieChart && (
              <span className="spinner-border" role="status"></span>
            )}
            <div className="pieChartContainer">
              <Pie data={data} options={options} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 col-sm-12 flex-column colsSpaces">
          <div className="chartStyle">
            <div className="tableCardHeader">
              <span className="tableCardHeaderTitle">{t("AllCampaigns")}</span>
              <span className="tableCardHeaderIcon">
                <img
                  className="cardsIcons"
                  src="images/Chevron.png"
                  alt="Chevron"
                  onClick={() => navigate("/AllCampaigns")}
                />
              </span>
            </div>
            <div className="tableDiv">
              <table className="cardTable">
                <thead>
                  <tr>
                    <th>{t("CampaignName")}</th>
                    {/* <th>Delivered %</th> */}
                    <th>{t("status")}</th>
                    <th>{t("totalSms")}</th>
                    <th>{t("Date")}</th>
                    <th>{t("time")}</th>
                    <th>{t("sender")}</th>
                    <th>{t("CampaignType")}</th>
                  </tr>
                </thead>
                {dataAllCampign.length > 0 ? (
                  <tbody>
                    {dataAllCampign.slice(0, 5).map((data, index) => (
                      <tr key={index}>
                        <td style={{ width: "500px" }}>{data.CampaignName}</td>
                        {/* <td>
      <div
        className="loadingBar"
        style={{ "--percentage": data.deliveredPercentage }}
      ></div>
    </td> */}
                        <td>
                          {" "}
                          <img
                            className="cardsIcons mr-1"
                            src={
                              data.Status === 1
                                ? "images/ready.png"
                                : "images/error.png"
                            }
                            alt="ready"
                          />
                          {data.Status === 1 ? t("Sent") : t("pending")}
                        </td>
                        <td>{data.RowsCount}</td>
                        <td>{data.SendDate}</td>
                        <td>{data.SendTime}</td>
                        <td>{data.Sender}</td>
                        <td>
                          {data.CampaignType === 1 ? (
                            <div className="oneToOneCampignContainer">
                              One:One
                            </div>
                          ) : (
                            <div className="bulkCampignContainer">Bulk</div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <div className="noDataStyle">{t("noData")}</div>
                )}
              </table>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-12 colsSpaces">
          <div className="dashboardRightSideCard">
            <span className="d-flex justify-content-between mb-2">
              {t("AllSenders")}
              <span className="addNewSenderBtnCard">
                {" "}
                <img
                  className="cardsIcons"
                  src="images/addIcon.png"
                  alt="add icon"
                  onClick={() => setAddSenderPopUp(true)}
                />
              </span>
            </span>
            <div className="sendersDashboardContainer">
              {dashboardSenderList &&
                dashboardSenderList.map((sender, index) => (
                  <div key={index} className="senderCard">
                    <span>{sender.Sender}</span>
                    <span>
                      {sender.Active ? (
                        <span className="senderActiveState">
                          <span className="circleGreen"></span>Active
                        </span>
                      ) : (
                        <span className="senderPendingState">
                          <span className="circleOrange"></span>Pending
                        </span>
                      )}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 col-sm-12 colsSpaces flex-column">
          <div className="chartStyle">
            <div className="tableCardHeader">
              <span className="tableCardHeaderTitle">
                {t("scaduleMessage")}
              </span>
              <span className="tableCardHeaderIcon">
                <img
                  className="cardsIcons"
                  src="images/Chevron.png"
                  alt="Chevron"
                  onClick={() => navigate("/scheduleReport")}
                />
              </span>
            </div>
            <div className="tableDiv">
              <table className="cardTable">
                <thead>
                  <tr>
                    <th>{t("CampaignName")}</th>
                    <th>{t("date")}</th>
                    <th>{t("sender")}</th>
                  </tr>
                </thead>
                {dataScheduledSms.length > 0 ? (
                  <tbody>
                    {/* Map over the dummy data */}
                    {dataScheduledSms.slice(0, 5).map((data, index) => (
                      <tr key={index}>
                        <td>{data.CampaignName}</td>
                        <td>{data.SendDate}</td>
                        <td>{data.Sender}</td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <div className="noDataStyle">{t("noData")}</div>
                )}
              </table>
            </div>
          </div>
        </div>

        <div className="col-md-3 col-sm-12 colsSpaces">
          <div className="dashboardRightSideCard">
            <ReadOnlyCalendar events={events} />
          </div>
        </div>

        <div className="col-md-4 col-sm-12 colsSpaces">
          <div className="dashboardRightSideCard">
            <div className="apiIntegrationCard">
              <img
                className="apiCardsIcons mr-1"
                src={"images/apiIntegration.png"}
                alt="apiIntegration"
              />
              <span>{t("apiIntegration")}</span>
              <div>
                {t("apiIntegrationCard")}
              </div>
              <button onClick={() => navigate("/ApiIntegration")}>
                Generate Key
              </button>
            </div>
          </div>
        </div>
      </div>
      <AddSenderPopUp />
    </div>
  );
}

export default Dashboard;
