import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../context";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import "./style.css";
export default function SideBar() {
  const [show, setShow] = useState(false);
  const [showConfigurations, setShowConfigurations] = useState(false);
  const [openSideBar, setOpenSideBar] = useState(true);
  const [mainLogoSrc, setMainLogoSrc] = useState('images/logo.png');
  const [miniLogoSrc, setMiniLogoSrc] = useState('images/miniLogo.png');
  const { role } = useContext(Context);
  const { t } = useTranslation();

  useEffect(() => {
    if (window.location.href.includes("portal.sms")) {
      setMainLogoSrc('images/epushLogo.png');
      setMiniLogoSrc('images/epushMiniLogo.png');
    } else {
      setMainLogoSrc('images/logo.png');
      setMiniLogoSrc('images/miniLogo.png');
    }
  }, []);

  function showSubMenu() {
    if (!show) setShow(true);
    else setShow(false);
  }

  function showConfigurationsSubMenu() {
    if (!showConfigurations) setShowConfigurations(true);
    else setShowConfigurations(false);
  }


  function showSideBarMenu() {
    if (!openSideBar) setOpenSideBar(true);
    else setOpenSideBar(false);
  }

  return (
    <div
      className={`sidebar ${i18next.language} ${!openSideBar ? "sidebarHide" : ""
        }`}
    >
      <div>
        <div className="logo-details">
          <span className="sideBarLogoContainer">
            <img className="mainLogo" src={mainLogoSrc} style={window.location.href.includes("portal.sms") ? { width: 'auto', height: 'auto' } : null} alt="main logo" />
          </span>
          <img className="miniLogo" src={miniLogoSrc} style={window.location.href.includes("portal.sms") ? { width: 'auto', height: 'auto' } : null} alt="main logo" />
          <i className="fas fa-bars bars" onClick={() => showSideBarMenu()}></i>
        </div>
        <ul className={`nav-links ${i18next.language}`}>
          <li className="mt-4" title="Dashboard">
            <NavLink to="/Dashboard" activeclassname="active" end>
              <img className={`sidebarIcon ${i18next.language}`} src="images/dashboard.png" alt="" />
              <span className="links_name">{t("dashboard")}</span>
            </NavLink>
          </li>

          {localStorage.getItem("userId") !== "356" && (
            <li title="Campaign">
              <div
                className={
                  show
                    ? "sideBarSubMenuDiv subMenueActive"
                    : "sideBarSubMenuDiv"
                }
                onClick={() => showSubMenu()}
              >
                {openSideBar && (
                  <div>
                    <img
                      className={`sidebarIcon ${i18next.language}`}
                      src="images/sideBarCampign.png"
                      alt=""
                    />
                    <span className="links_name">{t("campaign")}</span>
                  </div>
                )}

                <i
                  className={show ? "fas fa-chevron-up" : "fas fa-chevron-down"}
                ></i>
              </div>
            </li>
          )}
          {show && (
            <>
              {" "}
              <li
                className={`hidden-menu ${show ? "show" : ""}`}
                title="1:1 Message"
              >
                <NavLink
                  className="subMenu"
                  to="/"
                  activeclassname="active"
                  end
                >
                  <i
                    className={`fa-regular fa-message icon subMenuItem ${i18next.language}`}
                  ></i>
                  <span className="links_name">{t("oneToOneMessage")}</span>
                </NavLink>
              </li>
              <li title="Bulk Message">
                <NavLink
                  className="subMenu"
                  to="/sendBulkSms"
                  activeclassname="active"
                  end
                >
                  <i
                    className={`fa-regular fa-message icon subMenuItem ${i18next.language}`}
                  ></i>
                  <span className="links_name">{t("bulkMessage")}</span>
                </NavLink>
              </li>
              <li title="Schedule Report">
                <NavLink
                  className="subMenu"
                  to="/scheduleReport"
                  activeclassname="active"
                  end
                >
                  <i
                    className={`far fa-calendar icon subMenuItem ${i18next.language}`}
                  ></i>

                  <span className="links_name">{t("scheduledCampaigns")}</span>
                </NavLink>
              </li>
              <li title="AllCampaigns">
                <NavLink
                  className="subMenu"
                  to="/AllCampaigns"
                  activeclassname="active"
                >
                  <i
                    className={`fa-regular fa-file-lines icon subMenuItem ${i18next.language}`}
                  ></i>
                  <span className="links_name">{t("AllCampaigns")}</span>
                </NavLink>
              </li>
            </>
          )}

          <li title="Reports">
            <NavLink to="/reports" activeclassname="active">
              <img className={`sidebarIcon ${i18next.language}`} src="images/report.png" alt="" />
              <span className="links_name">{t("reportsTitle")}</span>
            </NavLink>
          </li>

          <li title="ReportPerOperator">
            <NavLink to="/reportPerOperator" activeclassname="active">
              <img className={`sidebarIcon ${i18next.language}`} src="images/report.png" alt="" />
              <span className="links_name">{t("reportPerOperator")}</span>
            </NavLink>
          </li>

          <li title="APIReports">
            <NavLink to="/APIReport" activeclassname="active">
              <img className={`sidebarIcon ${i18next.language}`} src="images/report.png" alt="" />
              <span className="links_name">{t("APIReport")}</span>
            </NavLink>
          </li>

          {localStorage.getItem("userId") !== "356" && (
            <li title="Senders">
              <NavLink to="/senders" activeclassname="active">
                <img
                  className={`sidebarIcon ${i18next.language}`}
                  src="images/sideBarSenders.png"
                  alt=""
                />
                <span className="links_name">{t("sendersTitle")}</span>
              </NavLink>
            </li>
          )}
          {(localStorage.getItem("admin") === "1" ||
            localStorage.getItem("admin") === "2" ||
            localStorage.getItem("userId") === "356") && (
              <li title="Accounts">
                <NavLink to="/accounts" activeclassname="active">
                  <img className={`sidebarIcon ${i18next.language}`} src="images/accounts.png" alt="" />
                  <span className="links_name">{t("accountsTitle")}</span>
                </NavLink>
              </li>
            )}
          {/* {role !== "1" && ( */}
          {role !== '356' && (<li title="Groups">
            <NavLink to="/contacts" activeclassname="active">
              <img className={`sidebarIcon ${i18next.language}`} src="images/accounts.png" alt="" />

              <span className="links_name">{t("groups")}</span>
            </NavLink>
          </li>)}
          {/* {role === "1" && ( */}
          <li title="PackagesHistory">
            <NavLink to="/packagesHistory" activeclassname="active">
              <img
                className={`sidebarIcon ${i18next.language}`}
                src="images/transactions.png"
                alt=""
              />
              <span className="links_name">{t("PackagesHistoryTitle")}</span>
            </NavLink>
          </li>
          <li title="Transactions">
            <NavLink to="/transaction" activeclassname="active">
              <img
                className={`sidebarIcon ${i18next.language}`}
                src="images/transactions.png"
                alt=""
              />
              <span className="links_name">{t("transactionTitle")}</span>
            </NavLink>
          </li>
          {/* )} */}

          <li>
            <NavLink to="/ApiIntegration" activeclassname="active">
              <img
                className={`sidebarIcon ${i18next.language}`}
                src="images/apiIntegrationIcon.png"
                alt=""
              />
              <span className="links_name">{t("apiIntegration")}</span>
            </NavLink>
          </li>

          {
            (localStorage.getItem("admin") === "1" ||
              localStorage.getItem("admin") === "2")
            && (
              <li title="Configurations">
                <div
                  className={
                    showConfigurations
                      ? "sideBarSubMenuDiv subMenueActive"
                      : "sideBarSubMenuDiv"
                  }
                  onClick={() => showConfigurationsSubMenu()}
                >
                  {openSideBar && (
                    <div>
                      <img
                        className={`sidebarIcon ${i18next.language}`}
                        src="images/settings.png"
                        alt=""
                      />
                      <span className="links_name">{t("Configurations")}</span>
                    </div>
                  )}

                  <i
                    className={showConfigurations ? "fas fa-chevron-up" : "fas fa-chevron-down"}
                  ></i>
                </div>
              </li>
            )}
          {showConfigurations && (
            <>
              {" "}
              <li
                className={`hidden-menu ${showConfigurations ? "show" : ""}`}
                title="Business Sector"
              >
                <NavLink
                  className="subMenu"
                  to="/BusinessSector"
                  activeclassname="active"
                  end
                >

                  <i className={`fas fa-briefcase icon subMenuItem ${i18next.language}`}></i>
                  <span className="links_name">{t("BusinessSector")}</span>
                </NavLink>
              </li>
              <li title="Price List">
                <NavLink
                  className="subMenu"
                  to="/PriceList"
                  activeclassname="active"
                  end
                >
                  <i className={`fas fa-receipt icon subMenuItem ${i18next.language}`} ></i>
                  <span className="links_name">{t("PriceList")}</span>
                </NavLink>
              </li>
            </>
          )}
          <li title="TermsAndConditions">
            <NavLink to="/termsAndConditions" activeclassname="active">
              <img className={`sidebarIcon ${i18next.language}`} src="images/terms.png" alt="" />
              <span className="links_name">{t("termsAndConditions")}</span>
            </NavLink>
          </li>
        </ul>
      </div>
      {/* <div className="log_out" onClick={logout}>
        <i
          className={`fa-solid fa-arrow-right-from-bracket logOutDiv ${i18next.language}`}
        ></i>
        <span className="links_name">{t("logOut")}</span>
      </div> */}
    </div>
  );
}
