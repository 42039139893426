import React from "react";
import i18next from "i18next";
import "./style.css";

export default function Field({
  inputFieldId,
  setOnChange,
  inputFieldValue,
  inputFieldType,
  isRequired,
  placeholder,
  labelName,
  isDisabled,
  style,
  max,

}) {

  return (
    <>
      <label className={`labelStyle ${i18next.language}`}>{labelName}</label>
      <input
        type={inputFieldType || "text"}
        className={`form-control ${i18next.language}`}
        required={isRequired}
        id={inputFieldId}
        value={inputFieldValue}
        onChange={(e) => setOnChange(e.target.value)}
        placeholder={placeholder}
        disabled={isDisabled}
        maxLength={max}
      />
    </>
  );
}
