import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react";
import Table from "../../component/newTable";
import { toast } from "react-toastify";
import {
  GetAllSenders,
  Delegation_Letter_Sample,
  ChangeSenderOperatorActivation
} from "../../API";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import Header from "../../component/header";
import AddSenderPopUp from "../../component/addSenderPopUp";
import "./style.css";

export default function Senders() {
  const [data, setData] = useState([]);
  const { role, addSenderPopUp, setAddSenderPopUp, reload, setReload } = useContext(Context);
  const [pageCount, setPageCount] = useState();
  const [senderFilter, setSenderFilter] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [toggleSideBar] = useState(false);


  const { t } = useTranslation();
  const handleCheckboxChange = (id, vodafone, etisalat, orange, we) => {
    ChangeSenderOperatorActivation({
      "@vodafone": vodafone,
      "@orange": orange,
      "@we": we,
      "@etisalat": etisalat,
      "@id": id,
    })
      .then((response) => {
        setReload(!reload);
      })
      .catch((err) => {
        toast.error(`${err}`);
      });
  };
  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "IDSmsHub",
      },
      {
        Header: (
          <div className="tableHeaderFilter">
            <div>{t("sender")}</div>
            <input
              type="text"
              className="searchFilter"
              value={senderFilter}
              onChange={(e) => setSenderFilter(e.target.value)}
              placeholder="Search"
            />
          </div>
        ),
        accessor: "Sender",
      },
      {
        Header: t("date"),
        accessor: "Date",
      },
      {
        Header: t("accountName"),
        accessor: "UserName",
      },
      {
        Header: t("commercialRegister"),
        accessor: "CommercialRegister",
        Cell: ({ row: { original } }) => {
          return (
            <a
              href={original.CommercialRegister}
              target="_blank"
              rel="noreferrer"
            >
              {t("commercialRegister")}
            </a>
          );
        },
      },
      {
        Header: t("taxCard"),
        accessor: "TaxCard",
        Cell: ({ row: { original } }) => {
          return (
            <a href={original.TaxCard} target="_blank" rel="noreferrer">
              {t("taxCard")}
            </a>
          );
        },
      },
      {
        Header: t("Delegation Letter"),
        accessor: "DelegationLetter",
        Cell: ({ row: { original } }) => {
          return (
            <a
              href={original.DelegationLetter}
              target="_blank"
              rel="noreferrer"
            >
              {t("Delegation Letter")}
            </a>
          );
        },
      },
      {
        Header: t("Vodafone"),
        accessor: "VodafoneActive",
        Cell: ({ row: { original } }) => {
          return (role != 1 && localStorage.getItem("admin") != 2) ? original.VodafoneActive === 1 ? (
            // <input type="checkbox" checked={true} readOnly />
            <div className="senderStatusActive">{t("active")}</div>
          ) : (
            // <input type="checkbox" checked={false} readOnly />
            <div className="senderStatusPending">{t("pending")}</div>
          ) : <input
            type="checkbox"
            checked={original.VodafoneActive}
            onChange={() => handleCheckboxChange(original.IDSmsHub, !original.VodafoneActive, original.EtisalatActive, original.OrangeActive, original.WEActive)}
          />
        },
      }
      ,
      {
        Header: t("Etisalat"),
        accessor: "EtisalatActive",
        Cell: ({ row: { original } }) => {
          return (role != 1 && localStorage.getItem("admin") != 2) ? original.EtisalatActive === 1 ? (
            // <input type="checkbox" checked={true} readOnly />
            <div className="senderStatusActive">{t("active")}</div>
          ) : (
            // <input type="checkbox" checked={false} readOnly />
            <div className="senderStatusPending">{t("pending")}</div>
          ) : <input
            type="checkbox"
            checked={original.EtisalatActive}
            onChange={() => handleCheckboxChange(original.IDSmsHub, original.VodafoneActive, !original.EtisalatActive, original.OrangeActive, original.WEActive)}
          />
        },
      }
      ,
      {
        Header: t("Orange"),
        accessor: "OrangeActive",
        Cell: ({ row: { original } }) => {
          return (role != 1 && localStorage.getItem("admin") != 2) ? original.OrangeActive === 1 ? (
            // <input type="checkbox" checked={true} readOnly />
            <div className="senderStatusActive">{t("active")}</div>
          ) : (
            // <input type="checkbox" checked={false} readOnly />
            <div className="senderStatusPending">{t("pending")}</div>
          ) : <input
            type="checkbox"
            checked={original.OrangeActive}
            onChange={() => handleCheckboxChange(original.IDSmsHub, original.VodafoneActive, original.EtisalatActive, !original.OrangeActive, original.WEActive)}
          />
        },
      }
      ,
      {
        Header: t("WE"),
        accessor: "WEActive",
        Cell: ({ row: { original } }) => {
          return (role != 1 && localStorage.getItem("admin") != 2) ? original.WEActive === 1 ? (
            // <input type="checkbox" checked={true} readOnly />
            <div className="senderStatusActive">{t("active")}</div>
          ) : (
            // <input type="checkbox" checked={false} readOnly />
            <div className="senderStatusPending">{t("pending")}</div>
          ) : <input
            type="checkbox"
            checked={original.WEActive}
            onChange={() => handleCheckboxChange(original.IDSmsHub, original.VodafoneActive, original.EtisalatActive, original.OrangeActive, !original.WEActive)}
          />
        },
      }
    ],
    [t, role, reload]
  );
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      setData([]);
      GetAllSenders({
        "@page": pageIndex,
        "@rowsPerPage": pageSize,
        "@sender": senderFilter,
      })
        .then((response) => {
          setIsLoading(false);
          setData(response.data.table1);
          setPageCount(response.data.table2[0].Total);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(`${err}`);
        });
    },
    [role, senderFilter, addSenderPopUp, reload]
  );

  const handleShow = () => {
    setAddSenderPopUp(true);
  };


  return (
    <div className="sendersContainer">
      <Header
        title={t("sendersTitle")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div className="tableMainTitles">
        <div className="senderTitleBtns">
          <button className="addSenderBtn" onClick={handleShow}>
            {t("addSenderBtn")}
          </button>
          <button
            className="downloadLetterBtn"
            disabled={isLoading}
            onClick={() => window.open(Delegation_Letter_Sample, "_self")}
          >
            {t("Delegation Letter")}
          </button>
        </div>
      </div>
      <div className="tableDiv">
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={isLoading}
          TotalRows={pageCount}
          tableHeader={t("sendersTitle")}
        />
        <AddSenderPopUp />
      </div>
    </div>
  );
}
